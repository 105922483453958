import React, { useCallback, useState } from "react";
import Footer from "../footer/footer.js";
import ImageViewer from "react-simple-image-viewer";

function GalleryPage(props) {
  const [currentImg, setCurrentImg] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    "https://lv7ms1pq6dm2sea8j1mrajzw-wpengine.netdna-ssl.com/wp-content/uploads/2014/05/shutterstock_789412672-1200x675.jpg",
    "https://www.czechuniversities.com/uploads/2020/08/6408.jpg",
    "https://wallpaperaccess.com/full/2245139.jpg",
    "https://www.ellucian.com/sites/default/files/styles/max_width_1920/public/uploads/images/2018/09/insights-article-meeting-students-expectations-for-a-digital-campus.jpg?itok=objiC9GM",
    "https://www.ellucian.com/sites/default/files/styles/max_width_1920/public/uploads/images/2018/09/insights-article-meeting-students-expectations-for-a-digital-campus.jpg?itok=objiC9GM",
    "https://wallpaperaccess.com/full/2245139.jpg",
    "https://www.czechuniversities.com/uploads/2020/08/6408.jpg",
    "https://lv7ms1pq6dm2sea8j1mrajzw-wpengine.netdna-ssl.com/wp-content/uploads/2014/05/shutterstock_789412672-1200x675.jpg",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImg(index);
    setIsViewerOpen(true);
  });

  const closeImageViewer = () => {
    setCurrentImg(0);
    setIsViewerOpen(false);
  };
  return (
    <div>
      <section id="galleryPage">
        <div className="container">
          <h1>Gallery</h1>
          <div className="galleryPageMain">
            {images.map((src, index) => (
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                width="300"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            ))}

            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImg}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}

            <div className="dummyGalleryPageBox"></div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
} // end of GalleryPage

export default GalleryPage;
