import React from "react";
import { NavLink } from "react-router-dom";
// images
import bannerImg from "../../assets/images/banner.webp";
import graphimg from "../../assets/images/grahp.png";
// jquery
import $ from "jquery";

function banner() {
  var lFollowX1 = 0,
    lFollowY1 = 0,
    x1 = 0,
    y1 = 0,
    friction1 = 1 / 30;

  var lFollowX2 = 0,
    lFollowY2 = 0,
    x2 = 0,
    y2 = 0,
    friction2 = 1 / 30;

  var lFollowX3 = 0,
    lFollowY3 = 0,
    x3 = 0,
    y3 = 0,
    friction3 = 1 / 30;

  function moveBackground() {
    x1 += (lFollowX1 - x1) * friction1;
    y1 += (lFollowY1 - y1) * friction1;

    x2 += (lFollowX2 - x2) * friction2;
    y2 += (lFollowY2 - y2) * friction2;

    x3 += (lFollowX3 - x3) * friction3;
    y3 += (lFollowY3 - y3) * friction3;

    let translate1 = "translate(" + x1 + "px, " + y1 + "px)";
    let translate2 = "translate(" + x2 + "px, " + y2 + "px)";
    let translate3 = "translate(" + x3 + "px, " + y3 + "px)";

    $(".bannerElementBox1").css({
      "-webit-transform": translate1,
      "-moz-transform": translate1,
      transform: translate1,
    });

    $(".bannerElementBox2").css({
      "-webit-transform": translate2,
      "-moz-transform": translate2,
      transform: translate2,
    });

    $(".bannerElementBox3").css({
      "-webit-transform": translate3,
      "-moz-transform": translate3,
      transform: translate3,
    });

    window.requestAnimationFrame(moveBackground);
  }

  $(window).on("mousemove click", function (e) {
    var lMouseX1 = Math.max(
      -90,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY1 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX1 = (30 * lMouseX1) / 100; // 100 : 12 = lMouxeX : lFollow
    lFollowY1 = (20 * lMouseY1) / 100;

    var lMouseX2 = Math.max(
      -60,
      Math.min(100, $(window).width() / 3 - e.clientX)
    );
    var lMouseY2 = Math.max(
      -100,
      Math.min(100, $(window).height() / 3 - e.clientY)
    );
    lFollowX2 = (20 * lMouseX2) / 90; // 100 : 12 = lMouxeX : lFollow
    lFollowY2 = (10 * lMouseY2) / 90;

    var lMouseX3 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY3 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX3 = (10 * lMouseX3) / 40; // 100 : 12 = lMouxeX : lFollow
    lFollowY3 = (10 * lMouseY3) / 30;
  });

  moveBackground();

  return (
    <div>
      <section id="banner">
        <div className="banerMain">
          <div className="banerContent">
            <h1>
              A good <span>education</span> is a foundation for better future
            </h1>
            <p>
              A community with hight expectation and high academic achievement
            </p>
            <NavLink to="/contact">Get Started</NavLink>
          </div>
          <div className="banerThumbnail">
            <div className="banerThumbnailBox">
              <img src={bannerImg} alt="" />
            </div>
            <div className="bannerElement1"></div>
            <div className="bannerElement2"></div>
            <div className="bannerElement3"></div>
            <div className="bannerElement4"></div>
            <div className="bannerElementBox1 mouseAnimBox">
              <h2>Easy to get Admission</h2>
              <p>Contact us now and get your admissions hassle-free</p>
            </div>
            <div className="bannerElementBox2 mouseAnimBox">
              <h2>Best Discount</h2>
              <p>Apply now and avail best discount.</p>
            </div>
            <div className="bannerElementBox3 mouseAnimBox">
              <h2>100% Success Rate</h2>
              <div className="bannerElementBoxImg">
                <img src={graphimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default banner;
