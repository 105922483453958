import React from "react";
import Footer from "../footer/footer.js";
// icons
import { BsFilterLeft } from "react-icons/bs";
// jquery
import $ from "jquery";

function professional() {
  $(".innerCourseListFilterBox").click(function () {
    $(".innerCourseTab").addClass("innerCourseTabActive");
    $(".shimmer").fadeIn();
  });

  return (
    <div>
      <section id="innerCourseBanner">
        <div className="container">
          <h1>Professional Courses</h1>
        </div>
      </section>

      <section id="innerCourseList">
        <div className="container">
          <div className="innerCourseListFilter">
            <div className="innerCourseListFilterBox">
              <i>
                <BsFilterLeft />
              </i>
              <p>Filter</p>
            </div>
          </div>
          <div className="innerCourseTab">
            <div className="innerCourseTabBox">
              <input type="radio" id="course1" name="course-radioBtn" />
              <label for="course1">
                M.B.A. (Master of Business Administration)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course2" name="course-radioBtn" />
              <label for="course2">B.Litt Tamil</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course3" name="course-radioBtn" />
              <label for="course3">B.A. English</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course4" name="course-radioBtn" />
              <label for="course4">B.Sc. Computer Science</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course5" name="course-radioBtn" />
              <label for="course5">B.Sc. Information Technology</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course6" name="course-radioBtn" />
              <label for="course6">Bachelor of Computer Application</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course7" name="course-radioBtn" />
              <label for="course7">B.Sc Costume Design and Fashion</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course8" name="course-radioBtn" />
              <label for="course8">B.Sc Visual Communication</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course9" name="course-radioBtn" />
              <label for="course9">Catering Science and Hotel Management</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course10" name="course-radioBtn" />
              <label for="course10">B.Com</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course11" name="course-radioBtn" />
              <label for="course11">B.Com Computer Application</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course12" name="course-radioBtn" />
              <label for="course12">B.B.A</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course13" name="course-radioBtn" />
              <label for="course13">B.S.W (Social Work)</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course14" name="course-radioBtn" />
              <label for="course14">B.Sc. Mathematics</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course15" name="course-radioBtn" />
              <label for="course15">B.Sc. Physics</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course16" name="course-radioBtn" />
              <label for="course16">B.Sc. Chemistry</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course17" name="course-radioBtn" />
              <label for="course17">B.Sc. Botany</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course18" name="course-radioBtn" />
              <label for="course18">B.Sc. Zoology</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course19" name="course-radioBtn" />
              <label for="course19">BSC Optometry</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course20" name="course-radioBtn" />
              <label for="course20">
                Bachelor of Library & Information Science (B.Lib.I.Sc)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course21" name="course-radioBtn" />
              <label for="course21">BBA - Airline & Airport Management</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course22" name="course-radioBtn" />
              <label for="course22">BBA (Shipping and Port Management)</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course23" name="course-radioBtn" />
              <label for="course23">
                BBA (Logistics and Supply Chain Management)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course24" name="course-radioBtn" />
              <label for="course24">
                BBA International Shipping and Air Carage Logistics Management I
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course25" name="course-radioBtn" />
              <label for="course25">
                BBA In Airport and Custome Care Management
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course26" name="course-radioBtn" />
              <label for="course26">B.Sc Animation & VFX</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course27" name="course-radioBtn" />
              <label for="course27">
                B.Sc Animation, Game Design & Development
              </label>
            </div>
          </div>
          <div className="innerCourseListMain">
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.B.A. (Master of Business Administration)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>1 Year (Common for all Specializations)</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>
                        1.1. Principles of Management and Organizational
                        Behaviour
                      </li>
                      <li>1.2. Managerial Economics</li>
                      <li>1.3. Accounting and Finance for Managers</li>
                      <li>1.4. Marketing Management</li>
                      <li>1.5. Human Resource Management</li>
                      <li>1.6. Quantitative Techniques for Management</li>
                      <li>1.7. Research Methods for Management</li>
                      <li>1.8. Corporate Communication</li>
                      <li>1.9. Operations Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.B.A General</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>2 Year (28 Specializations)</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Productions and Operations Management</li>
                      <li>2.2. Executive Communication</li>
                      <li>2.3. Brand Management</li>
                      <li>2.4. Management Information Systems</li>
                      <li>2.5. International Business</li>
                      <li>2.6. Event Management</li>
                      <li>2.7. Micro-Finance: Perspectives and Operations</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Information System Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Software Project Management</li>
                      <li>2.2. Database Management Systems</li>
                      <li>2.3. E-Commerce</li>
                      <li>2.4. Enterprise Resource Planning</li>
                      <li>2.5. Data Mining and Data Warehousing</li>
                      <li>2.6. Knowledge Management & Information Systems</li>
                      <li>2.7. Information Security, System Control & Audit</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Export Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. International Business Environment</li>
                      <li>2.2. Export Trade & Documentation</li>
                      <li>2.3. Export Finance Procedure</li>
                      <li>2.4. FOREX Management</li>
                      <li>2.5. International Project Management</li>
                      <li>2.6. Logistics Management</li>
                      <li>2.7. International Marketing</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Entrepreneurship</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Entrepreneurial Finance</li>
                      <li>2.2. Entrepreneurship Development</li>
                      <li>2.3. Project Management</li>
                      <li>2.4. Information Technology and E-Commerce</li>
                      <li>
                        2.5. Technology Innovation and Sustainable Enterprise
                      </li>
                      <li>2.6. Business Plan and Ethics</li>
                      <li>2.7. Managing Diversity</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Financial Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Indian Capital Market and Financial System</li>
                      <li>2.2. Management of Financial Service</li>
                      <li>2.3. Capital Budgeting and Financial Decisions</li>
                      <li>2.4. Working Capital Management</li>
                      <li>2.5. Risk Management and Insurance</li>
                      <li>2.6. Security Analysis and Portfolio Management</li>
                      <li>2.7. International Financial Management</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Financial Service</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Marketing of Financial Services</li>
                      <li>2.2. Mutual Funds and Merchant Banking</li>
                      <li>2.3. Legal aspects of financial services</li>
                      <li>2.4. Mergers and Acquisitions</li>
                      <li>
                        2.5. Financial Intermediaries, Financial Institutions
                        and Regulators
                      </li>
                      <li>2.6. Risk Management & Insurance</li>
                      <li>2.7. Treasury Management</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Hospital Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Hospital Architecture, Planning and Design</li>
                      <li>2.2. Materials Management</li>
                      <li>2.3. Hospital Operations - I (Patient Care)</li>
                      <li>
                        2.4. Hospital Operations - II (Supportive Services)
                      </li>
                      <li>2.5. Bio-Sciences and Epidemiology</li>
                      <li>2.6. Hospital Information System</li>
                      <li>2.7. Health Laws and Policies</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>International Business</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. International Human Resource Management</li>
                      <li>2.2. International Business Environment</li>
                      <li>2.3. International Marketing Management</li>
                      <li>
                        2.4. International Financial Management and Accounting
                      </li>
                      <li>2.5. International Business Law</li>
                      <li>2.6. International Banking</li>
                      <li>2.7. Global Business Ethics</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Marketing Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Consumer Behavior</li>
                      <li>2.2. Industrial & Services Marketing</li>
                      <li>2.3. New Product Development</li>
                      <li>2.4. Marketing Research</li>
                      <li>2.5. Advertising and Sales Promotion</li>
                      <li>2.6. Logistics and Sales Management</li>
                      <li>2.7. International Marketing</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Human Resources Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Personnel Management Concepts</li>
                      <li>2.2. Methodology of Training and Development</li>
                      <li>2.3. Human Resource Development & Planning</li>
                      <li>2.4. Industrial Relations Management</li>
                      <li>2.5. Compensation Management</li>
                      <li>
                        2.6. Performance Management: Systems and Strategies
                      </li>
                      <li>2.7. Organizational Development and change</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Investment Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>
                        2.1. Derivatives Management and Commodities Markets
                      </li>
                      <li>2.2. Indian Capital Market & Financial System</li>
                      <li>2.3. International Financial Markets</li>
                      <li>2.4. Risk Management and Insurance</li>
                      <li>2.5. Mutual Funds in India</li>
                      <li>2.6. Security Analysis and Portfolio Management</li>
                      <li>2.7. Project Finance and Management</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Retail Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Buyer Behavior</li>
                      <li>2.2. Retail Operations Management</li>
                      <li>2.3. International Retailing</li>
                      <li>2.4. Retail Planning</li>
                      <li>2.5. Logistics and Sales Promotion</li>
                      <li>2.6. Retail Sales Techniques and Promotion</li>
                      <li>2.7. Direct & Network Marketing</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Service Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Service Marketing</li>
                      <li>2.2. Customer Relationship Management</li>
                      <li>2.3. Health Care Services Management</li>
                      <li>2.4. Hospitality Services Management</li>
                      <li>2.5. Public Services Management</li>
                      <li>2.6. Financial Services Management</li>
                      <li>2.7. Total Quality Service Management</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Banking and Finance</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Financial Institutions and Services</li>
                      <li>2.2. Management Control Systems</li>
                      <li>2.3. Banking and Insurance Marketing</li>
                      <li>
                        2.4. Management of Banking and Insurance Companies
                      </li>
                      <li>2.5. Commercial Banking and Role of RBI</li>
                      <li>2.6. Mutual Fund Management</li>
                      <li>2.7. Merchant Banking</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Project Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Project Formulation and Appraisa</li>
                      <li>2.2. Project Support System</li>
                      <li>2.3. Project Planning and Execution</li>
                      <li>2.4. Project Risk Management</li>
                      <li>2.5. Project Control System</li>
                      <li>2.6. Project Management Software</li>
                      <li>2.7. Small Business Management</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Tourism and Hotel Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Hospitality Management</li>
                      <li>2.2. Tourism Planning and Marketing</li>
                      <li>2.3. Service Marketing</li>
                      <li>2.4. International Hospitality Law</li>
                      <li>2.5. International Tourism Management</li>
                      <li>2.6. Tourism Business Environments</li>
                      <li>2.7. Tourism Products</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Technology Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Total Quality Management</li>
                      <li>2.2. Production Planning and Control</li>
                      <li>2.3. Technology Acquisitions & Diffusion</li>
                      <li>2.4. Technology Finance</li>
                      <li>2.5. Goal programming in Management</li>
                      <li>2.6. Technology Forecasting</li>
                      <li>2.7. R & D Management</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.B.A Logistic & Supply Chain Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2.1. Logistic Management</li>
                      <li>2.2. Retail Store and Visual Merchandising</li>
                      <li>2.3. Quality Management and QualityTechniques</li>
                      <li>2.4. Retail Marketing and CRM</li>
                      <li>2.5. Logistic and Supply Chain Management</li>
                      <li>2.6. Export Trade and Documentation</li>
                      <li>2.7. International Marketing</li>
                      <li>2.8. Business Environment and Ethics</li>
                      <li>2.9. Strategic Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  A pass in any Degree Course. Candidate who have completed the
                  study under (11 +1 +3)/10+2+3 /10+3 (Dip) + 3 (UG) are
                  eligible
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>MCA (Master of Computer Application)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Java Programming and Data Structures</li>
                      <li>2. Relational Database Management Systems</li>
                      <li>3. Visual Programming</li>
                      <li>4. Computer Networks</li>
                      <li>5. Software Engineering</li>
                      <li>Elective - I</li>
                      <li>
                        Practical - I Java Programming and Data Structures Lab
                      </li>
                      <li>Practical - II RDBMS Lab</li>
                      <li>Practical - III Visual Programming Lab</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Analysis and Design of Information Systems</li>
                      <li>7. Software Testing</li>
                      <li>8. Data Mining and Warehousing</li>
                      <li>Elective – II</li>
                      <li>Practical - IV - Software Testing Lab</li>
                      <li>Project Work and Viva Voce</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  BCA, B.Sc. Computer science / Computer technology /
                  Information technology or Any Degree with Mathematics at +2
                  level or with at least one paper in Mathematics or Statistics
                  at Degree level/ B.Sc. Computer System & Design/B.Sc.
                  Information System/B.Com CA with one paper as Business
                  mathematics and Business Statistics
                </p>
                <span>Fees</span>
                <p>₹ 23,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default professional;
