import React from "react";
import emailjs from 'emailjs-com'
import { useRef, useState } from "react";
// images
import contactImg from "../../assets/images/contact.jpeg";

function Contact() {
  const[name,setName]= useState('');
  const[number,setNumber] = useState('');
  const[email,setEmail] = useState('');
  const[message,setMessage] = useState('');
  const[submit,setSubmit] = useState('Submit');
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ck51sab', 'template_eewvszg', form.current, 'jbqkVitggxg9ft867')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };
 
  return (
    <div>
      <section id="contact">
        <div className="contactThumbnail">
          <img src={contactImg} />
        </div>
        <div className="contactForm">
          <h1>Contact Us</h1>
          <p>
            Thank you for your interest in Capital Academy Do you have any query
            or need any assistance? We are here to help you... Fill in your
            details in the form below and we will get back to you.{" "}
          </p>
          <form ref={form} onSubmit={sendEmail}>
            <div className="formGroup">
              <label>Name</label>
              <input type="text" 
              name="name"
              value={name}
              required
              onChange={(e)=>setName(e.target.value)}
              />
            </div>
            <div className="formGroup">
              <label>Phone</label>
              <input type="number"
              name="number" 
              value={number}
              required
              onChange={(e)=>setNumber(e.target.value)}
              />
            </div>
            <div className="formGroup">
              <label>Email</label>
              <input type="email" 
              name="email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
              />
            </div>
            <div className="formGroup">
              <label>Message</label>
              <textarea
              name="message"
              value={message}
              onChange={(e)=>setMessage(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" value="Send" onClick={()=>{
              setSubmit('Submitting...')
              setTimeout(setSubmit('Submitted',1000));
              }}>{submit}</button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
