import React from "react";
import Footer from "../footer/footer.js";
// icons
import { BsFilterLeft } from "react-icons/bs";
// jquery
import $ from "jquery";

function PgCourses() {
  $(".innerCourseListFilterBox").click(function () {
    $(".innerCourseTab").addClass("innerCourseTabActive");
    $(".shimmer").fadeIn();
  });

  return (
    <div>
      <section id="innerCourseBanner">
        <div className="container">
          <h1>PG Courses</h1>
        </div>
      </section>

      <section id="innerCourseList">
        <div className="container">
          <div className="innerCourseListFilter">
            <div className="innerCourseListFilterBox">
              <i>
                <BsFilterLeft />
              </i>
              <p>Filter</p>
            </div>
          </div>
          <div className="innerCourseTab">
            <div className="innerCourseTabBox">
              <input type="radio" id="course1" name="course-radioBtn" />
              <label for="course1">B.A. Tamil</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course2" name="course-radioBtn" />
              <label for="course2">B.Litt Tamil</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course3" name="course-radioBtn" />
              <label for="course3">B.A. English</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course4" name="course-radioBtn" />
              <label for="course4">B.Sc. Computer Science</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course5" name="course-radioBtn" />
              <label for="course5">B.Sc. Information Technology</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course6" name="course-radioBtn" />
              <label for="course6">Bachelor of Computer Application</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course7" name="course-radioBtn" />
              <label for="course7">B.Sc Costume Design and Fashion</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course8" name="course-radioBtn" />
              <label for="course8">B.Sc Visual Communication</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course9" name="course-radioBtn" />
              <label for="course9">Catering Science and Hotel Management</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course10" name="course-radioBtn" />
              <label for="course10">B.Com</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course11" name="course-radioBtn" />
              <label for="course11">B.Com Computer Application</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course12" name="course-radioBtn" />
              <label for="course12">B.B.A</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course13" name="course-radioBtn" />
              <label for="course13">B.S.W (Social Work)</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course14" name="course-radioBtn" />
              <label for="course14">B.Sc. Mathematics</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course15" name="course-radioBtn" />
              <label for="course15">B.Sc. Physics</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course16" name="course-radioBtn" />
              <label for="course16">B.Sc. Chemistry</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course17" name="course-radioBtn" />
              <label for="course17">B.Sc. Botany</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course18" name="course-radioBtn" />
              <label for="course18">B.Sc. Zoology</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course19" name="course-radioBtn" />
              <label for="course19">BSC Optometry</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course20" name="course-radioBtn" />
              <label for="course20">
                Bachelor of Library & Information Science (B.Lib.I.Sc)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course21" name="course-radioBtn" />
              <label for="course21">BBA - Airline & Airport Management</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course22" name="course-radioBtn" />
              <label for="course22">BBA (Shipping and Port Management)</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course23" name="course-radioBtn" />
              <label for="course23">
                BBA (Logistics and Supply Chain Management)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course24" name="course-radioBtn" />
              <label for="course24">
                BBA International Shipping and Air Carage Logistics Management I
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course25" name="course-radioBtn" />
              <label for="course25">
                BBA In Airport and Custome Care Management
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course26" name="course-radioBtn" />
              <label for="course26">B.Sc Animation & VFX</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course27" name="course-radioBtn" />
              <label for="course27">
                B.Sc Animation, Game Design & Development
              </label>
            </div>
          </div>
          <div className="innerCourseListMain">
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Mathematics</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Algebra</li>
                      <li>2. Real Analysis</li>
                      <li>3. Differential Equations</li>
                      <li>4. Numerical Methods</li>
                      <li>5. Complex Analysis</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Mechanics</li>
                      <li>7. Operations Research</li>
                      <li>8. Topology</li>
                      <li>9. Computer Programming (C++ Theory)</li>
                      <li>10. Functional Analysis</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Mathematics / B.Sc. Mathematics Computer
                  Application/B.Sc., B.Ed Mathematics (4yrs)
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Physics</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Classical Mechanics & Mathematical Physics</li>
                      <li>2. Quantum Mechanics</li>
                      <li>3. Electromagnetic Theory & Optical Physics</li>
                      <li>4. Nuclear Physics & Spectroscopy</li>
                      <li>5. Practical - I : General Physics</li>
                      <li>6. Practical - II : Electronics</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>7. Advanced Electronics</li>
                      <li>8. Condensed Matter Physics</li>
                      <li>9. Electrodynamics and Plasma Physics</li>
                      <li>10. Computational Methods & Programming</li>
                      <li>Practical -III: Advanced Physics</li>
                      <li>Practical -IV: Advanced Electronics</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Physics with Mathematics as one of the allied subjects
                  or B.Sc. Physics and Computer Application with Mathematics as
                  one of the subjects.
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Chemistry</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Organic Chemistry - I</li>
                      <li>2. Inorganic Chemistry - I</li>
                      <li>3. Physical Chemistry - I</li>
                      <li>4. Practical - I - Organic Chemistry I</li>
                      <li>5. Practical - II - Inorganic Chemistry I</li>
                      <li>6. Practical - III - Physical Chemistry I</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>7. Organic Chemistry - II</li>
                      <li>8. Inorganic Chemistry - II</li>
                      <li>9. Physical Chemistry - II</li>
                      <li>10. Practical - IV - Organic Chemistry II</li>
                      <li>11. Practical - V - Inorganic Chemistry II</li>
                      <li>12. Practical - VI - Physical Chemistry II</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Chemistry with Physics / Maths / Zoology / Botany as
                  allied subjects or B.Sc. Polymer Chemistry/B.Sc.Industrial
                  Chemistry with Physics and Mathematics as an allied subject
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Botany</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Phycology, Mycology, Bacteriology & Virology</li>
                      <li>2. Bryophytes, Pteridophytes & Gymnosperms</li>
                      <li>3. Genetics, Plant Breeding & Biostatistics</li>
                      <li>4. Cell and Molecular Biology</li>
                      <li>5. Anatomy, Embryology & Tissue Culture</li>
                      <li>Practical - I : Papers 1 & 2</li>
                      <li>Practical - II : Papers 3, 4 & 5</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Environmental Botany & Conservation Biology</li>
                      <li>7. Angiosperms Systematics</li>
                      <li>8. Bio Technology & Genetic Engineering</li>
                      <li>9. Plant Physiology & Biochemistry</li>
                      <li>Practical -III : Papers 6 & 7</li>
                      <li>Practical -IV : Papers 8 & 9</li>
                      <li>
                        Choose any one of the following special paper (No
                        practicals) Food Science and Nutrition,.Horticulture
                        Forest Botany, Ethanobotany
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Botany / Plant Biology and Plant Bio Technology / Plant
                  Science / Biology/ Micro Biology / Bio-Technology.
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Zoology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Invertebrate and Vertebrate Biology</li>
                      <li>2. Cell Biology & Genetics</li>
                      <li>3. Economic Zoology</li>
                      <li>4. Biochemistry & Biophysics</li>
                      <li>
                        5. Environmental Science & Biodiversity Conservation
                      </li>
                      <li>
                        Practical-I: Invertebrate and Vertebrate Biology, Cel
                        Biology & Genetics,Biochemistry & Biophysics,
                        Environmental Science & Biodiversity
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Animal Physiology & Endocrinology</li>
                      <li>7. Microbiology & Immunology</li>
                      <li>8. Evolution & Phylogeny</li>
                      <li>9. Development Biology & Human Welfare</li>
                      <li>10. Bio Technology & Bio informatics</li>
                      <li>
                        Practical - II : Animal Physiology &Endocrinology,
                        Microbiology & Immunology, Evolution & Phylogeny on
                        Developmental Biology & Human Welfare, Bio Technology &
                        Bio Informatics
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Zoology / Animal Science and Bio-Technology/Animal
                  Science / Advanced Zoology and Bio-Technology / Applied
                  Science / Life Science/ Biotechnology / Biochemistry and Wild
                  Life biology
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc Environmental Science</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Water Pollution and Management</li>
                      <li>2. Air Pollution and Management</li>
                      <li>3. Soil Pollution and Solid waste Management</li>
                      <li>4. Instrumentation Methods of Analysis</li>
                      <li>Practical-I: Air, Water and Soil Analysis</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Management of Energy Resources</li>
                      <li>6. Natural Resources and Conservation</li>
                      <li>7. Environmental Engineering</li>
                      <li>8. Environmental Impact Assessment</li>
                      <li>Practical - II : Waste Water Treatment</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  Any Bachelor Degree in Science / Engineering / Medicine /
                  Agriculture / Fisheries Science / Veterinary Science/Pharmacy
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Bio-Informatics</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Fundamentals of Biological Systems</li>
                      <li>2. Computational methods for Sequence analysis</li>
                      <li>3. Programming in C and PERL</li>
                      <li>4. Molecular interactions</li>
                      <li>Practical -I : C and Pearl programming</li>
                      <li>Practical-ll : Data banks & Sequence analysis</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Genomics and Proteomics</li>
                      <li>6. Systems biology</li>
                      <li>7. Programming in VB with RDBMS</li>
                      <li>
                        8. Molecular modeling and Computer aided drug design
                      </li>
                      <li>Practical - Ill Programming in VB with RDBMS</li>
                      <li>Practical - IV Computer aided Drug design</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  Bachelor Degree in any one of the following Disciplines: Life
                  Sciences/ Chemical Sciences / Physical Sciences /Mathematics /
                  Statistics/ Veterinary Sciences / Bio information / Bio
                  Chemistry / Biology / Polymer Chemistry / Electronics /
                  Statistics Mathematics / Physics / Fishery Sciences /
                  Agricultural Sciences / Medical, Paramedical and
                  Pharmaceutical Sciences / Food and Nutrition/ Food Chemistry /
                  Food Technology / Computer Science / BCA/ Information
                  Technology/Forestry Science / Environmental Science/ BHMS/
                  B.E/ B.Tech degree in Computer Science &Engg /Information
                  Technology / Bio-Technology /Bioinformatics/Biomedical Science
                  Any degree course of B.Sc /B.Tech in all Science discipline
                  B.V.Sc. B.Pharm. M.B.B.S. and B.D.S.
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Computer Science</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Advanced Computer Architecture</li>
                      <li>2. Computer Graphics and Multimedia</li>
                      <li>3. Software Engineering</li>
                      <li>4. Computer Networks</li>
                      <li>5. Computer Graphics and Multimedia Lab</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Advanced Operating System</li>
                      <li>7. Internet programming and Web Design</li>
                      <li>8. Data Mining and Warehousing</li>
                      <li>9. Internet programming and Web Design Lab</li>
                      <li>10. Project Work and Viva Voce</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Computer Science / Computer Technology / Information
                  Technology/Software Systems / Electronics / BCA/ B.Sc.
                  (Applied Science [IT/CT])/B.Sc. Software Engineering /Computer
                  System & Design/Information System/B.E Computer Science/ B.E.
                  Electronics and Communication Engineering
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Information Technology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Object Oriented Analysis and Design</li>
                      <li>2. Advanced Java Programming</li>
                      <li>3. Distributed Computing</li>
                      <li>4. Multimedia Systems</li>
                      <li>5. Practical - I : Advanced Java Lab</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Programming in C# and .NET Framework</li>
                      <li>7. Component Based systems</li>
                      <li>8. Web Services</li>
                      <li>9. Practical-ll : C# and.Net Programming Lab</li>
                      <li>10. Project</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc. Computer Science / Computer Technology / Information
                  Technology/Software Systems / Electronics / BCA/ B.Sc.
                  (Applied Science [IT/CT])/B.Sc. Software Engineering /Computer
                  System & Design/Information System/B.E Computer Science/ B.E.
                  Electronics and Communication Engineering
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Applied Psycology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Advanced General Psychology</li>
                      <li>2. Life Span Psychology</li>
                      <li>3. Advanced Social Psychology</li>
                      <li>4. Organizational Behavior</li>
                      <li>Practical -Experimental Psychology-I</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Psychopathology and Mental Hygiene</li>
                      <li>6. Research Methodology</li>
                      <li>7. Health Psychology</li>
                      <li>8. Human Resource Management</li>
                      <li>
                        9. Project Work or Theory Paper Councelling Psychology
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree from any Recognized University</p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Costume Design and Fashion</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Costumes and Textiles of the World</li>
                      <li>2. Indian Textile Industry</li>
                      <li>3. Research Methodology and Statistics</li>
                      <li>4. Fashion Merchandising</li>
                      <li>5. InternationalTrade and Documentation</li>
                      <li>Practical I -Ornaments and Accessories</li>
                      <li>Practical II - Draping for Fashion Design</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. CAD in Fashion Designing</li>
                      <li>7. Technical Textiles</li>
                      <li>8. Apparel Quality Standard and Implementation</li>
                      <li>9. Textile Testing</li>
                      <li>Practical III - Textile Testing</li>
                      <li>Practical IV - Designs with Prints</li>
                      <li>Practical V - Advanced Garments Construction.</li>
                      <li>Project</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Sc, (CDF) Fashion Design / Textile & Clothing /Apparel
                  Designing / and Fashion Art / B.Sc., Apparel Design. B.Sc
                  textile and apparel design, B.Sc textiles & clothing (or) Any
                  B.Sc degree related to textiles and fashion discipline/B.Voc.
                  in Garment Designing
                </p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Com</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Corporate Accounting</li>
                      <li>2. Marketing Management</li>
                      <li>3. Information Technology in Business</li>
                      <li>4. Cost and Management Accounting</li>
                      <li>5. Human Resource Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>1. Internet and E-commerce</li>
                      <li>2. Financial and Investment Management</li>
                      <li>3. Business Environment</li>
                      <li>4. Direct Taxes</li>
                      <li>5. International Business</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Com / B.Com (CA) / B.Com (E-Com.) / B.B.M / B.B.M(CA) /
                  B.B.A / B.C.S/B.C.S (CA) / B.Com (CS) / B.Com (CS) with C.A /
                  B.Com (Co-operation) / B.Com (Co-operation) with CAand
                  Bachelor’s degree in Bank Management.
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Com. (Computer Application)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Managerial Economics</li>
                      <li>2. Cost and Management Accounting</li>
                      <li>3. Database Management System</li>
                      <li>4. Object Oriented Programming With C++</li>
                      <li>Practical 1 : Computer Applications</li>
                      <li>(MS Office , C++ & Tally)</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Marketing Management</li>
                      <li>6. Financial and Investment Management</li>
                      <li>7. E-Commerce</li>
                      <li>8. Visual Basic</li>
                      <li>
                        Practical II : ComputerApplications (VB and E-commerce)
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.Com / B.Com (CA) / B.Com (E-Com.) / B.Com (C.S) / B.Com
                  (C.S) with C.A / B.Com (Co-operation) / B.Com (Cooperation)
                  with CA / B.B.M /B.B.M(CA) / B.B.A / B.C.S / B.C.S(CA) /
                  B.Sc(CS) / BCA and Bachelors degree in Bank Management
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.S.W. (Master of Social Work)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Introduction to Social Work</li>
                      <li>2. Social Case Work & Social Group Work</li>
                      <li>3. Sociology for Social work practice</li>
                      <li>4. Psychology for Social work practice</li>
                      <li>5. Community Organization</li>
                      <li>6. Field work Practicum.</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>7. Social Work Research & Statistics</li>
                      <li>8. Social Welfare Administration</li>
                      <li>9. Management of Organization</li>
                      <li>
                        10. Working with Special Categories ofPeople-Women,
                        Children, Disables,Youth, Aged Labour and SC/ST
                      </li>
                      <li>11. Rural & Urban Community Development</li>
                      <li>12. Mini Research Project.</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any UG Degree</p>
                <span>Fees</span>
                <p>₹ 16,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Tamil</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. இக்கால இலக்கியம்</li>
                      <li>2. தொல்காப்பியம் எழுத்ததிகாரம்(இளம்பூரணர் உரை)</li>
                      <li>3. பக்தி இலக்கியம்</li>
                      <li>4. இலக்கியத் திறனாய்வியல்</li>
                      <li>5. நாட்டுப்புறவியல்</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. சங்க இலக்கியம்</li>
                      <li>7. தொல்காப்பியம் சொல்லதிகாரம் (இளம்பூரணர் உரை)</li>
                      <li>
                        8. தொல்காப்பியம் - பொருளதிகாரம் (செய்யுளியல், உவமயியல்
                        நீங்கலாக)
                      </li>
                      <li>9. காப்பியங்கள்</li>
                      <li>10. சிற்றிலக்கியங்கள்</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Degree with Part -1 Tamil</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. English Literature</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. British Literature - I(Chaucer to Dryden)</li>
                      <li>
                        2. British Literature - II ( Romantic age to modern
                        period)
                      </li>
                      <li>3. American Literature</li>
                      <li>4. The English Language</li>
                      <li>5. Indian Writing in English</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Shakespeare</li>
                      <li>7. Common wealth Literature</li>
                      <li>8. African/African American English Studies</li>
                      <li>9. Literary Theory</li>
                      <li>10. Methods of Teaching English</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  B.A English Literature, B.A Literature(CA) or any graduate
                  course with part II English at UG level.
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Journalism and Mass Communication</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Year</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Introduction to Communication</li>
                      <li>2. Reporting</li>
                      <li>3. Editing</li>
                      <li>4. Media History, Laws and Ethics</li>
                      <li>5. Broadcast Journalism</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Theories of Communication</li>
                      <li>7. Development Communication</li>
                      <li>8. Marketing Communication</li>
                      <li>9. Print Production Technologies</li>
                      <li>10. Film Studies and Photography</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree.</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. History</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. History of India up to 1206 A.D.</li>
                      <li>2. History of India from 1206 to 1707 A.D.</li>
                      <li>
                        3. History of British Administration from 1757 to
                        1947A.D.
                      </li>
                      <li>
                        4. ConstitutionalHistoryofIndiafromA.D.1773A.D.1950.
                      </li>
                      <li>5. History of Tamilnadu upto 1336 A.D</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. History of Europe from 1789 - 1945 A.D</li>
                      <li>
                        7. International Relations and Diplomacy from A.D.1914
                        to A.D. 1991.
                      </li>
                      <li>8. History of U.S.A. from A.D. 1865 to A.D.1974.</li>
                      <li>
                        9. History of the Far East from A.D. 1800 to A.D.1965.
                      </li>
                      <li>10. Historiography</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree.</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Economics</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Micro Economics</li>
                      <li>2. Macro Economics & Monetary Policies.</li>
                      <li>3. Public Economics</li>
                      <li>4. International Trade</li>
                      <li>5. Indian Economy</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Agricultural Economics</li>
                      <li>7. Industrial Economics</li>
                      <li>8. Research Methodology and Statistics</li>
                      <li>9. Human Resource Development</li>
                      <li>10. Environmental Economics</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  Any Graduate with Economics / Econometrics / Mathematics
                  /Statistics/ Business Economics / Managerial Economics as one
                  of the papers/ Business Statistics/Business Mathematics and
                  Engineering.
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Political Science & Public Administration</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Principles of Political Science</li>
                      <li>2. Indian Political System</li>
                      <li>3. Political thought [Eastern & Western]</li>
                      <li>4. Principles of Public Administration</li>
                      <li>5. Administrative Thinkers</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Public Policy Analysis</li>
                      <li>7. Modern Political Analysis</li>
                      <li>8. Development Administration</li>
                      <li>9. Personal Administration</li>
                      <li>10. Financial Administration</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Socialogy</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Foundation of Socialogy</li>
                      <li>2. Social Thoughts</li>
                      <li>3. Social Demography</li>
                      <li>4. Social of Indian society</li>
                      <li>5. Social of Change and Development</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Research Methodology and Statistics</li>
                      <li>7. Socialogical Theories.</li>
                      <li>8. Social Problems.</li>
                      <li>9. Gender and Society</li>
                      <li>10. Current Trends in Socialogical Theories</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Criminology & Police Administration</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Principles of Police Administration</li>
                      <li>2. Indian Administrative System</li>
                      <li>3. Criminology - An Introduction</li>
                      <li>4. Law of Crimes</li>
                      <li>5. Police Personal Administration</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Criminal Procedure and Evidence Act</li>
                      <li>7. Penology and Correctional Administration</li>
                      <li>8. Prevention of Crime and Delinquency</li>
                      <li>9. Psycology of Crime and Delinquency</li>
                      <li>10. Victimology</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Geography</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Physical basis of Geography</li>
                      <li>
                        2. Geography of Indian with special Reference to Tamil
                        Nadu
                      </li>
                      <li>3. Advance cartography</li>
                      <li>4. Environmental Geography</li>
                      <li>
                        5. Practical - I - Representation of Geographical data.
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. CAD in Fashion Designing</li>
                      <li>7. Agricultural Geography</li>
                      <li>8. Urban Geography</li>
                      <li>9. Remote Sensing and GIs</li>
                      <li>10. Practical - II - Map and image Interpretation</li>
                      <li>Project Work</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>B.Sc.Geography</p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc Visual Communication</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Image and Imagination</li>
                      <li>2. Basics of Visual Communication</li>
                      <li>3. Computer Graphics Media Design</li>
                      <li>4. Development communication</li>
                      <li>5. Information and Communication Technology</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Writing for the Media</li>
                      <li>7. Commercial Broadcasting</li>
                      <li>8. Communication Research Methods</li>
                      <li>9. Fundamentals of Sound</li>
                      <li>10. Projects Study</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree.</p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.Sc. Animation and VFX</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Advanced Animation Art</li>
                      <li>2. Advanced Digital Image Compositing</li>
                      <li>3. Advanced Modeling</li>
                      <li>4. Advanced Texturing</li>
                      <li>5. Advanced Lighting</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Advanced Rigging</li>
                      <li>7. Advanced Animation</li>
                      <li>8. Advanced Editing</li>
                      <li>9. Advanced Compositing</li>
                      <li>10. Advanced 3D Fx</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree</p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Master of Library and Information Science</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Foundation of Library and Information Science</li>
                      <li>
                        2. Knowledge Organization – I Classification Theory
                      </li>
                      <li>
                        3. Knowledge Organization – II Classification Practice
                      </li>
                      <li>
                        4. Knowledge Organization Introduction to Information
                        and Communication Technology
                      </li>
                      <li>5. Information Sources</li>
                      <li>6. Management of Library and Information Centres</li>
                      <li>7. Information Processing – I Cataloguing Theory</li>
                      <li>
                        8. Information Processing – II Cataloguing Practice
                      </li>
                      <li>9. Information Literacy</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>1. Research Methods</li>
                      <li>2. Information Services and System</li>
                      <li>3. Information System management</li>
                      <li>4. Information Storage & Retrieval(Theory)</li>
                      <li>5. Marketing of Information Products and Services</li>
                      <li>6. Digital Libraries</li>
                      <li>7. Knowledge Management</li>
                      <li>8. E-Publishing</li>
                      <li>9. Bibliometrics</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>B.L.I.S (Bachelor of Library & Information Science)</p>
                <span>Fees</span>
                <p>₹ 16,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>M.A. Yoga for Human Excellence</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 2 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Origin and Growth of Yoga</li>
                      <li>2. Physical Health</li>
                      <li>3. Rejuvenating the life force and mind</li>
                      <li>4. Sublimation and Social Welfare</li>
                      <li>5. Yoga Practice - I</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Yoga for Modern Age</li>
                      <li>7. Science of Divinity and Realisation of Self</li>
                      <li>8. Principles of world community life</li>
                      <li>9. World Peace Plans</li>
                      <li>10. Yoga Practice - II</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Bachelor Degree.</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default PgCourses;
