import React from "react";
import Achievements from "../achievements/achievements.js";
import Contact from "../contact/contact.js";
import Footer from "../footer/footer.js";

// images
import aboutimg from "../../assets/images/aboutBanner.jpeg";
import aboutDetailsimg from "../../assets/images/aboutPageImg.jpg";

function aboutPage() {
  return (
    <div>
      <section id="aboutPageBanner">
        <div className="container">
          <div className="aboutPageBannerMain">
            <div className="aboutPageBannerContent">
              <h1>Welcome To CMA family</h1>
            </div>
            <div className="aboutPageBannerThumbnail">
              <div className="aboutPageBannerThumbnailBox">
                <img src={aboutimg} />
              </div>
              <p>
                Capital Academy was established in 2018 under the leadership of
                two young and dynamic professionals in Kerala. The academy is a
                certified institution under world’s second largest cost &
                management accountant body called ICAI. It is a professional,
                statutory body of cost accountants in India.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Achievements />

      <section id="aboutDetails">
        <div className="container">
          <div className="aboutDetailsMain">
            <div className="aboutDetailsThumbnail">
              <img src={aboutDetailsimg} />
            </div>
            <div className="aboutDetailContent">
              <h2>About us</h2>
              <p>
                Capital Academy is one stop educational academy, business setup
                and consulting company, managed by specialized team of Business
                Analysts, Chartered Accountants, Corporate Lawyers and Financial
                Professionals. We are the leading name in the market engaged in
                offering a wide assortment of distance education, credit
                transfer, taxation, accounts, audits, GST filling and overseas
                education.
              </p>
              <p>
                Having known for our quality of services, delivering the
                projects on time, we have clients spread across the globe.
                Furthermore, we equip students to comprehend, contribute to, and
                achieve in a quickly changing world. We provide professional and
                high-quality services while ensuring that resources are used
                efficiently. We advocate for educational excellence.
              </p>
            </div>
          </div>
          <div className="aboutMissionVision">
            <div className="aboutMissionVisionBox">
              <h2>Mission</h2>
              <ul>
                <li>
                  We aspire to be a professional, community-minded, and
                  forward-thinking educational academy that provides flexible,
                  customised training and education solutions to help
                  individuals and organisations reach their aims and goals.
                </li>
                <li>
                  We strive to improve students' knowledge and competence in
                  their field of study by providing them with high-quality
                  education and to place a strong emphasis on each student's
                  spiritual, moral, intellectual, social, emotional, and
                  physical growth.
                </li>
              </ul>
            </div>
            <div className="aboutMissionVisionBox">
              <h2>Vision</h2>
              <ul>
                <li>
                  We aim to encourage excellence in learning and teaching, to be
                  the best in class and chosen partner for learners and
                  companies in the field of finance and accounting, and to serve
                  as a useful resource for society and business.
                </li>
                <li>
                  To provide a platform for international business and
                  professional education.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Contact />

      <Footer />
    </div>
  );
}

export default aboutPage;
