import React, { Component } from "react";
import "./Home.css";
import Banner from "../banner/banner.js";
import Achievements from "../achievements/achievements.js";
import About from "../about/about.js";
import Courses from "../courses/courses.js";
import Services from "../services/services.js";
import WorkingProcess from "../workingProcess/workingProcess.js";
import Gallery from "../gallery/Gallery.js";
import Testimonial from "../testimonial/testimonial.js";
import Contact from "../contact/contact.js";
import Footer from "../footer/footer.js";

function Home() {
  return (
    <main>
      <Banner />
      <Achievements />
      <About />
      <Courses />
      <Services />
      <WorkingProcess />
      <Gallery />
      <Testimonial />
      <Contact />
      <Footer />
    </main>
  );
}

export default Home;
