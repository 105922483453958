import React from "react";

function achievements() {
  return (
    <div>
      <section id="achievements">
        <div className="container">
          <div className="achievementsMain">
            <div className="achievementsBox">
              <h1>80 +</h1>
              <p>Professional Teacher</p>
            </div>
            <div className="achievementsBox">
              <h1>20 +</h1>
              <p>New Courses every year</p>
            </div>
            <div className="achievementsBox">
              <h1>700 +</h1>
              <p>Registered Students</p>
            </div>
            <div className="achievementsBox">
              <h1>100%</h1>
              <p>Success Rate</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default achievements;
