import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// images
import galleryImg1 from "../../assets/images/gallery/img1.jpeg";
import galleryImg2 from "../../assets/images/gallery/img2.jpg";
import galleryImg3 from "../../assets/images/gallery/img3.jpg";
import galleryImg4 from "../../assets/images/gallery/img4.jpg";
// jquery
import $ from "jquery";

const options = {
  margin: 60,
  responsive: {
    0: {
      items: 2,
      margin: 20,
    },
    768: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  },
};
function gallery() {
  return (
    <div>
      <section id="gallery">
        <div className="container-fluid">
          <OwlCarousel
            className="owl-theme owl-carousel"
            {...options}
            loop
            autoplay
            nav
            autoplayTimeout={3500}
            smartSpeed={2550}
            fluidSpeed={2000}
          >
            <div className="galleryBox">
              <img src={galleryImg1} />
            </div>
            <div className="galleryBox">
              <img src={galleryImg2} />
            </div>
            <div className="galleryBox">
              <img src={galleryImg3} />
            </div>
            <div className="galleryBox">
              <img src={galleryImg4} />
            </div>
          </OwlCarousel>
        </div>
      </section>
    </div>
  );
}

export default gallery;
