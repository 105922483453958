import React from "react";
import Testimonial from "../testimonial/testimonial-slider";
// images
import testimonialElement1 from "../../assets/images/testimonial/testimonialElement1.jpg";
import testimonialElement2 from "../../assets/images/testimonial/testimonialElement2.jpg";
import testimonialElement3 from "../../assets/images/testimonial/testimonialElement3.jpg";
import testimonialElement4 from "../../assets/images/testimonial/testimonialElement4.jpg";
import testimonialElement5 from "../../assets/images/testimonial/testimonialElement5.jpg";
import testimonialElement6 from "../../assets/images/testimonial/testimonialElement6.jpg";
// jquery
import $ from "jquery";

function testimonial(props) {
  var lFollowX5 = 0,
    lFollowY5 = 0,
    x5 = 0,
    y5 = 0,
    friction5 = 1 / 30;

  var lFollowX6 = 0,
    lFollowY6 = 0,
    x6 = 0,
    y6 = 0,
    friction6 = 1 / 30;

  var lFollowX7 = 0,
    lFollowY7 = 0,
    x7 = 0,
    y7 = 0,
    friction7 = 1 / 30;

  var lFollowX8 = 0,
    lFollowY8 = 0,
    x8 = 0,
    y8 = 0,
    friction8 = 1 / 30;

  var lFollowX9 = 0,
    lFollowY9 = 0,
    x9 = 0,
    y9 = 0,
    friction9 = 1 / 30;

  var lFollowX10 = 0,
    lFollowY10 = 0,
    x10 = 0,
    y10 = 0,
    friction10 = 1 / 30;

  function moveBackground() {
    x5 += (lFollowX5 - x5) * friction5;
    y5 += (lFollowY5 - y5) * friction5;

    x6 += (lFollowX6 - x6) * friction6;
    y6 += (lFollowY6 - y6) * friction6;

    x7 += (lFollowX7 - x7) * friction7;
    y7 += (lFollowY7 - y7) * friction7;

    x8 += (lFollowX8 - x8) * friction8;
    y8 += (lFollowY8 - y8) * friction8;

    x9 += (lFollowX9 - x9) * friction9;
    y9 += (lFollowY9 - y9) * friction9;

    x10 += (lFollowX10 - x10) * friction10;
    y10 += (lFollowY10 - y10) * friction10;

    let translate5 = "translate(" + x5 + "px, " + y5 + "px)";
    let translate6 = "translate(" + x6 + "px, " + y6 + "px)";
    let translate7 = "translate(" + x7 + "px, " + y7 + "px)";
    let translate8 = "translate(" + x8 + "px, " + y8 + "px)";
    let translate9 = "translate(" + x9 + "px, " + y9 + "px)";
    let translate10 = "translate(" + x10 + "px, " + y10 + "px)";

    $(".testimonialElement1").css({
      "-webit-transform": translate5,
      "-moz-transform": translate5,
      transform: translate5,
    });

    $(".testimonialElement2").css({
      "-webit-transform": translate6,
      "-moz-transform": translate6,
      transform: translate6,
    });

    $(".testimonialElement3").css({
      "-webit-transform": translate7,
      "-moz-transform": translate7,
      transform: translate7,
    });

    $(".testimonialElement4").css({
      "-webit-transform": translate8,
      "-moz-transform": translate8,
      transform: translate8,
    });

    $(".testimonialElement5").css({
      "-webit-transform": translate9,
      "-moz-transform": translate9,
      transform: translate9,
    });

    $(".testimonialElement6").css({
      "-webit-transform": translate10,
      "-moz-transform": translate10,
      transform: translate10,
    });

    window.requestAnimationFrame(moveBackground);
  }

  $(window).on("mousemove click", function (e) {
    var lMouseX5 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY5 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX5 = (10 * lMouseX5) / 30; // 100 : 12 = lMouxeX : lFollow
    lFollowY5 = (10 * lMouseY5) / 20;

    var lMouseX6 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY6 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX6 = (10 * lMouseX6) / 70; // 100 : 12 = lMouxeX : lFollow
    lFollowY6 = (10 * lMouseY6) / 60;

    var lMouseX7 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY7 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX7 = (10 * lMouseX7) / 50; // 100 : 12 = lMouxeX : lFollow
    lFollowY7 = (10 * lMouseY7) / 30;

    var lMouseX8 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY8 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX8 = (10 * lMouseX8) / 50; // 100 : 12 = lMouxeX : lFollow
    lFollowY8 = (10 * lMouseY8) / 40;

    var lMouseX9 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY9 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX9 = (10 * lMouseX9) / 40; // 100 : 12 = lMouxeX : lFollow
    lFollowY9 = (10 * lMouseY9) / 30;

    var lMouseX10 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY10 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX10 = (10 * lMouseX10) / 30; // 100 : 12 = lMouxeX : lFollow
    lFollowY10 = (10 * lMouseY10) / 20;
  });

  moveBackground();

  return (
    <div>
      <section id="testimonial">
        <div className="container">
          <h1>What our Student saying</h1>
          <div className="testimonialMain">
            <Testimonial />
          </div>
        </div>
        <div className="testimonialElement testimonialElement1">
          <img src={testimonialElement1} />
        </div>
        <div className="testimonialElement testimonialElement2">
          <img src={testimonialElement2} />
        </div>
        <div className="testimonialElement testimonialElement3">
          <img src={testimonialElement3} />
        </div>
        <div className="testimonialElement testimonialElement4">
          <img src={testimonialElement4} />
        </div>
        <div className="testimonialElement testimonialElement5">
          <img src={testimonialElement5} />
        </div>
        <div className="testimonialElement testimonialElement6">
          <img src={testimonialElement6} />
        </div>
      </section>
    </div>
  );
}

export default testimonial;
