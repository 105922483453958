import React from "react";
import { NavLink } from "react-router-dom";

function services() {
  return (
    <div>
      <section id="services">
        <div className="container">
          <h1>Our Services</h1>
          <div className="servicesMain">
            <NavLink to="/courses" className="servicesBox">
              <h2>Distance Education</h2>
              <p>
                Are you a student who has dropped out of school owing to
                personal reasons or is unable to continue your education? If
              </p>
            </NavLink>
            <NavLink to="/servicesPage" className="servicesBox">
              <h2>Credit Transfer</h2>
              <p>
                Credit transfer is a university program and is legally approved
                by UGC and central government. By using this credit transfer
                method, students will not lose their already
              </p>
            </NavLink>
            <NavLink to="/servicesPage" className="servicesBox">
              <h2>Taxation</h2>
              <p>
                Evolution of regulatory framework revolving around taxation has
                become very complex. This is majorly due to an increase in
                cross-border business models.
              </p>
            </NavLink>
            <NavLink to="/servicesPage" className="servicesBox">
              <h2>Accounts</h2>
              <p>
                Availability of accounting in any business transactions ignites
                the business to run with efficiency, effectiveness and accuracy
                manner on all the activities undertaken.
              </p>
            </NavLink>
            <NavLink to="/servicesPage" className="servicesBox">
              <h2>Audits</h2>
              <p>
                Around the globe, countries are involved in introducing complex
                regulatory requirements for better compliance and protection of
                investors. Laws have been broadened.
              </p>
            </NavLink>
            <NavLink to="/servicesPage" className="servicesBox">
              <h2>GST Filing</h2>
              <p>
                The Goods and Services Tax (GST) is an indirect tax levied on
                the supply of goods and services in India. Every taxpayer
                registered under GST has to file GST returns.
              </p>
            </NavLink>
            <NavLink to="/servicesPage" className="servicesBox">
              <h2>Study Abroad</h2>
              <p>
                At Capital Academy, we are committed to assisting Indian
                students who want to pursue their education in overseas
                educational institutions. We provide quality career
              </p>
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
}

export default services;
