import React from "react";
import Contact from "../contact/contact.js";
import Footer from "../footer/footer.js";
import { NavLink } from "react-router-dom";
// images
import courseimg1 from "../../assets/images/couserImg1.jpg";
import courseimg2 from "../../assets/images/couserImg2.jpg";
import courseimg3 from "../../assets/images/couserImg3.jpg";

function coursePage() {
  return (
    <div>
      <section ction id="courses" className="coursePage">
        <div className="container">
          <h1>Our Popular Courses</h1>
          <div className="coursesMain">
            <NavLink to="/ugcourses" className="coursesBox">
              <div className="coursesBoxThumbnail">
                <img src={courseimg1} />
              </div>
              <div className="coursesBoxContent">
                <h2>UG Courses</h2>
                <div className="courseCount">Total 42 Courses</div>
              </div>
            </NavLink>
            <NavLink to="/pgcourses" className="coursesBox">
              <div className="coursesBoxThumbnail">
                <img src={courseimg2} />
              </div>
              <div className="coursesBoxContent">
                <h2>PG Courses</h2>
                <div className="courseCount">Total 20 Courses</div>
              </div>
            </NavLink>
            <NavLink to="/professional" className="coursesBox">
              <div className="coursesBoxThumbnail">
                <img src={courseimg3} />
              </div>
              <div className="coursesBoxContent">
                <h2>Professional Courses</h2>
                <div className="courseCount">Total 29 Courses</div>
              </div>
            </NavLink>
          </div>
        </div>
      </section>

      <Contact />

      <Footer />
    </div>
  );
}

export default coursePage;
