import React from "react";
import Footer from "../footer/footer.js";
import Contact from "../contact/contact.js";
// icons
import { BsTelephone } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";

function contactPage() {
  
  return (
    <div>
      <section id="contactPage">
        <div className="container">
          <h1>Contact Us</h1>
          <p>
            Thank you for your interest in Capital Academy Do you have any query
            or need any assistance?
          </p>
          <div className="contactPageMain">
            <div className="contactPageBox">
              <span>
                <BsTelephone />
              </span>
              <h3>Phone</h3>
              <a href="tel:+919496834655">94968 34655</a>
            </div>
            <div className="contactPageBox">
              <span>
                <GoLocation />
              </span>
              <h3>Location</h3>
              <a>
                Nandhanam Arcad, Opp Kochukodungallur Devi Temple, High School
                Juction Kollam
              </a>
            </div>
            <div className="contactPageBox">
              <span>
                <AiOutlineMail />
              </span>
              <h3>Mail</h3>
              <a href="mailto:capital_academy@outlook.in">
                capital_academy@outlook.in
              </a>
            </div>
          </div>
        </div>
      </section>

      <Contact />

      <Footer />
    </div>
  );
}

export default contactPage;
