import "./App.css";
import Header from "./components/Header/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import AboutPage from "./components/AboutPage/aboutPage";
import ServicesPage from "./components/services/servicesPage";
import Courses from "./components/coursePage/coursePage";
import Testimonial from "./components/testimonialPage/testimonialPage";
import Gallery from "./components/gallerPage/GalleryPage";
import Contact from "./components/contactPage/contactPage";
import UgCourses from "./components/coursePage/UgCourses";
import PgCourses from "./components/coursePage/PgCourses";
import Professional from "./components/coursePage/professional";
import ScrollToTop from "./components/Scrolltop/Scrolltop";

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/capital" exact element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/servicesPage" element={<ServicesPage />} />
          <Route path="/testimonialPage" element={<Testimonial />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/ugcourses" element={<UgCourses />} />
          <Route path="/pgcourses" element={<PgCourses />} />
          <Route path="/professional" element={<Professional />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
