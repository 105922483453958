import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../footer/footer.js";

// images
import serviceimg1 from "../../assets/images/services/img1.jpg";
import serviceimg2 from "../../assets/images/services/img2.jpg";
import serviceimg3 from "../../assets/images/services/img3.jpg";
import serviceimg4 from "../../assets/images/services/img4.jpg";
import serviceimg5 from "../../assets/images/services/img5.jpg";
import serviceimg6 from "../../assets/images/services/img6.jpg";
import serviceimg7 from "../../assets/images/services/img7.jpg";

function servicesPage(props) {
  return (
    <div>
      <section id="servicesPage">
        <div className="container">
          <h1>
            We have been in this industry for many years and have continuously
            provided effective training programs. At Capital Academy, we
            continue to coach and guide students to become qualified in a unique
            talent by providing the correct education at the right time. Many of
            the services we provide are distance education, credit transfer,
            taxation, accounts, audits, GST filling and study abroad.
          </h1>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg1} />
            </div>
            <div className="servicesPageContent">
              <h2>Distance Education</h2>
              <p>
                Are you a student who has dropped out of school owing to
                personal reasons or is unable to continue your education? If so,
                this is your chance to enrol in UGC accredited universities'
                UG/PG/Professional programmes. Capital Academy has been
                recognised as the best distance education institution for
                Degree, PG and Professional course aspirants and ensures genuine
                on-line instruction for the enrolled students. All course
                certifications are worldwide recognised, and these certificates
                are also acknowledged and authorised by the UGC.
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/courses" className="viewBtn">
                  View Courses
                </NavLink>
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg2} />
            </div>
            <div className="servicesPageContent">
              <h2>Credit Transfer</h2>
              <p>
                Credit transfer is a university program and is legally approved
                by UGC and central government. By using this credit transfer
                method, students will not lose their already existing credit and
                allows one to complete their degree even if they are failed or
                discontinued. For the purpose of doing credit transfer for the
                previous university, one should complete their half semester
                along with or about 50% education or a semester must be
                completed. Students can apply for the degree of credit transfer
                for the same trade that they have been completed from their
                previous university.
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg3} />
            </div>
            <div className="servicesPageContent">
              <h2>Taxation</h2>
              <p>
                Evolution of regulatory framework revolving around taxation has
                become very complex. This is majorly due to an increase in
                cross-border business models. Managing such complexity has
                become a necessity for the enterprises in order to maximize
                value from every important business decision. While we ensure,
                that you maintain compliance, we make definitely sure that we
                create value for your business, giving you the best tax advice &
                assistance.
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg4} />
            </div>
            <div className="servicesPageContent">
              <h2>Accounts</h2>
              <p>
                Availability of accounting in any business transactions ignites
                the business to run with efficiency, effectiveness and accuracy
                manner on all the activities undertaken. This leads to more
                productions since the management will make the right decision
                and proper planning due to the good flow of transactions in a
                business. At Capital Academy, we coordinate the efforts of all
                our engaged specialist in areas like annual accounts, project
                reports, and finalisation of reports to add value to your
                business.
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg5} />
            </div>
            <div className="servicesPageContent">
              <h2>Audits</h2>
              <p>
                Around the globe, countries are involved in introducing complex
                regulatory requirements for better compliance and protection of
                investors. Laws have been broadened. The only way for companies
                to succeed is by swiftly implementing such regulatory
                requirements. So, with that said due to such complexities, our
                clients require assistance on various financial and accounting
                matters. The clients expect irrefutable assurance which can
                withstand any regulatory audit or scrutiny. We have a proven
                performance history for providing our clients with the best
                services. With our risk-oriented approach and as modern
                auditors, we offer you more than merely auditing the correctness
                of your accounts.{" "}
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg6} />
            </div>
            <div className="servicesPageContent">
              <h2>GST Filing</h2>
              <p>
                The Goods and Services Tax (GST) is an indirect tax levied on
                the supply of goods and services in India. Every taxpayer
                registered under GST has to file GST returns. These returns are
                used to calculate the tax liability by the government. GST is an
                evolving system of taxation and the government keeps updating
                the rules and regulations around it. So, it is important to stay
                up-to-date to be able to file your returns correctly. Our GST
                return filing service helps taxpayers like you to get it done
                easily.{" "}
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
          <div className="servicesPageMain">
            <div className="servicesPageThumbnail">
              <img src={serviceimg7} />
            </div>
            <div className="servicesPageContent">
              <h2>Study Abroad</h2>
              <p>
                At Capital Academy, we are committed to assisting Indian
                students who want to pursue their education in overseas
                educational institutions. We provide quality career guidance and
                counselling to students so they can make the right decision. We
                provide detailed information about Overseas Education as well as
                Educational Institutions in countries like UK, Germany and
                Canada. We are dedicated to assisting with career counselling,
                country selection, university selection, scholarships, education
                loans, visa application, tuition fee deposit, pre-departure
                briefing and traveling arrangement.
              </p>
              <div className="servicesPageContentBtns">
                <NavLink to="/contact" className="ContactBtn">
                  Contact Now
                </NavLink>
                <a
                  className="erpBtn"
                  href="https://capitalacademyedu.com/erpcapital/"
                >
                  Register Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default servicesPage;
