import React from "react";
// images
import aboutimg from "../../assets/images/aboutImg.jpg";
// jquery
import $ from "jquery";

function about() {
  var lFollowX4 = 0,
    lFollowY4 = 0,
    x4 = 0,
    y4 = 0,
    friction4 = 1 / 30;

  function moveBackground() {
    x4 += (lFollowX4 - x4) * friction4;
    y4 += (lFollowY4 - y4) * friction4;

    let translate4 = "translate(" + x4 + "px, " + y4 + "px)";

    $(".aboutThumbnailElement").css({
      "-webit-transform": translate4,
      "-moz-transform": translate4,
      transform: translate4,
    });

    window.requestAnimationFrame(moveBackground);
  }

  $(window).on("mousemove click", function (e) {
    var lMouseX4 = Math.max(
      -10,
      Math.min(100, $(window).width() / 2 - e.clientX)
    );
    var lMouseY4 = Math.max(
      -100,
      Math.min(100, $(window).height() / 2 - e.clientY)
    );
    lFollowX4 = (10 * lMouseX4) / 40; // 100 : 12 = lMouxeX : lFollow
    lFollowY4 = (10 * lMouseY4) / 30;
  });

  moveBackground();

  return (
    <div>
      <section id="about">
        <div className="container">
          <div className="aboutMain">
            <h1>Welcome To CMA family</h1>
            <p>
              Capital Academy was established in 2018 under the leadership of
              two young and dynamic professionals in Kerala. The academy is a
              certified institution under world’s second largest cost &
              management accountant body called ICAI. It is a professional,
              statutory body of cost accountants in India.
            </p>
            <div className="aboutDetails">
              <div className="aboutDetailsThumbnail">
                <img src={aboutimg} />
                <div className="aboutThumbnailElement"></div>
              </div>
              <div className="aboutDetailsContent">
                <h2>About us</h2>
                <p>
                  Capital Academy is one stop educational academy, business
                  setup and consulting company, managed by specialized team of
                  Business Analysts, Chartered Accountants, Corporate Lawyers
                  and Financial Professionals. We are the leading name in the
                  market engaged in offering a wide assortment of distance
                  education, credit transfer, taxation, accounts, audits, GST
                  filling and overseas education.
                </p>
                <p>
                  Having known for our quality of services, delivering the
                  projects on time, we have clients spread across the globe.
                  Furthermore, we equip students to comprehend, contribute to,
                  and achieve in a quickly changing world.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default about;
