import React from "react";
import Footer from "../footer/footer.js";
// images
import testimonialElement1 from "../../assets/images/testimonial/testimonialElement1.jpg";
import testimonialElement2 from "../../assets/images/testimonial/testimonialElement2.jpg";
import testimonialElement3 from "../../assets/images/testimonial/testimonialElement3.jpg";

function testimonialPage() {
  return (
    <div>
      <section id="testimonialPage">
        <div className="testimonialPageBox">
          <div className="testimonialPageBoxProfile">
            <img className="img" src={testimonialElement1} />
          </div>
          <div className="testimonialPageBoxContent">
            <p>
              The instructors can deliver a rich learning experience for each
              student at the student's convenience by utilising technology. The
              course availability is excellent, the interactive tools are simple
              to use, and assistance is always accessible at the click of a
              mouse or the other end of a phone line. I would definitely
              recommend this academy to other students.
            </p>
            <h3>Afsal KT</h3>
            <span>Palakkad</span>
          </div>
        </div>
        <div className="testimonialPageBox">
          <div className="testimonialPageBoxProfile">
            <img className="img" src={testimonialElement3} />
          </div>
          <div className="testimonialPageBoxContent">
            <p>
              Taking online education at Capital Academy has been quite
              beneficial to me. The courses are well-organized, and the
              professors are helpful and prompt in responding to doubts. Thank
              you, Capital academy, for allowing me to maintain a flexible
              schedule while completing my degree."
            </p>
            <h3>Shahda</h3>
            <span>Kannur</span>
          </div>
        </div>
        <div className="testimonialPageBox">
          <div className="testimonialPageBoxProfile">
            <img className="img" src={testimonialElement2} />
          </div>
          <div className="testimonialPageBoxContent">
            <p>
              Capital academy’s online courses are ideal for anyone with a need
              for distance learning. They enable me to acquire a high-quality
              education while maintaining my normally demanding professional and
              personal schedules. Thank you to all the instructors and I would
              really recommend this academy to my near ones.
            </p>
            <h3>Aiswarya</h3>
            <span>Ottapalam</span>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default testimonialPage;
