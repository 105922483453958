import React from "react";
import { NavLink } from "react-router-dom";
// image
import footerLogoImg from "../../assets/images/capitaLogoWhite.png";
// icons
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedinOption } from "react-icons/gr";
import { GrPinterest } from "react-icons/gr";
import { FaRss } from "react-icons/fa";
import { GrGooglePlus } from "react-icons/gr";
import { RiHeart3Line } from "react-icons/ri";

function footer() {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="footerMain">
            <div className="footerBox1">
              <div className="footerLogo">
                <img src={footerLogoImg} />
              </div>
              <p>
                Capital Academy was established in 2018 under the leadership of
                two young and dynamic professionals in Kerala.
              </p>
              <div className="footerSocialLink">
                <div className="footerSocialLinkBox">
                  <GrFacebookOption />
                </div>
                <div className="footerSocialLinkBox">
                  <GrTwitter />
                </div>
                <div className="footerSocialLinkBox">
                  <GrLinkedinOption />
                </div>
                <div className="footerSocialLinkBox">
                  <GrPinterest />
                </div>
                <div className="footerSocialLinkBox">
                  <FaRss />
                </div>
                <div className="footerSocialLinkBox">
                  <GrGooglePlus />
                </div>
              </div>
            </div>
            <div className="footerBox2">
              <h2>Useful Links</h2>
              <ul>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7482ff" : "black",
                        fontWeight: isActive ? "500" : "400",
                      };
                    }}
                    to="/"
                    className="isActive"
                  >
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7482ff" : "black",
                        fontWeight: isActive ? "500" : "400",
                      };
                    }}
                    to="/about"
                    exact
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7482ff" : "black",
                        fontWeight: isActive ? "500" : "400",
                      };
                    }}
                    to="/servicesPage"
                    exact
                  >
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7482ff" : "black",
                        fontWeight: isActive ? "500" : "400",
                      };
                    }}
                    to="/courses"
                  >
                    Our Courses
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7482ff" : "black",
                        fontWeight: isActive ? "500" : "400",
                      };
                    }}
                    to="/testimonialPage"
                  >
                    Testimonial
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => {
                      return {
                        color: isActive ? "#7482ff" : "black",
                        fontWeight: isActive ? "500" : "400",
                      };
                    }}
                    to="/gallery"
                  >
                    Gallery
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" className="navContact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footerBox2">
              <h2>Our Courses</h2>
              <ul>
                <li>
                  <NavLink to="/ugcourses">UG Courses</NavLink>
                </li>
                <li>
                  <NavLink to="/pgcourses">PG Courses</NavLink>
                </li>
                <li>
                  <NavLink to="/professional">Professional Courses</NavLink>
                </li>
              </ul>
            </div>
            <div className="footerBox3">
              <h2>Contact</h2>
              <a href="tel:+919496834655">94968 34655</a>
              <h2>Email</h2>
              <a href="mailto:capital_academy@outlook.in">
                capital_academy@outlook.in
              </a>
              <h2>Address</h2>
              <a>
                Nandhanam Arcad, Opp Kochukodungallur Devi Temple, High School
                Juction Kollam
              </a>
            </div>
          </div>
          <div className="footerStrip">
            <p>© 2022, All Rights Reserved.</p>
            <p>
              Designed with{" "}
              <span>
                <RiHeart3Line />
              </span>{" "}
              <a href="https://esightsolutions.com/" target="_blank">
                Esight Solutions
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default footer;
