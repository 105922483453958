import { React, useEffect } from "react";
import "./Header.css";
import Logo from "../../assets/images/capitaLogo.png";
import { NavLink } from "react-router-dom";
import { GrClose } from "react-icons/gr";

const Header = () => {
  useEffect(() => {
    let sidemenu = document.getElementById("resSidemenu");
    let navBox = document.querySelector(".navBarBox");

    navBox.addEventListener("click", (e) => {
      e.preventDefault();
      sidemenu.classList.add("sidemenuActive");
    });

    let navClose = document.getElementById("navClose");

    navClose.addEventListener("click", (e) => {
      e.preventDefault();
      sidemenu.classList.remove("sidemenuActive");
    });

    let homeEl = document.querySelector(".home");
    let aboutEl = document.querySelector(".about");
    let servicesEl = document.querySelector(".services");
    let courseEl = document.querySelector(".course");
    let testimonial = document.querySelector(".testi");
    let galleryEl = document.querySelector(".gallry");
    let navContact = document.querySelector(".navEl");

    aboutEl.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });

    servicesEl.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });

    courseEl.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });

    testimonial.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });

    galleryEl.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });

    navContact.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });

    homeEl.addEventListener("click", (e) => {
      sidemenu.classList.remove("sidemenuActive");
    });
  }, []);
  return (
    <div>
      <nav>
        <div className="container">
          <div className="navLogo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="navLink">
            <ul>
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7482ff" : "black",
                      fontWeight: isActive ? "500" : "400",
                    };
                  }}
                  to="/"
                  className="isActive"
                  exact
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7482ff" : "black",
                      fontWeight: isActive ? "500" : "400",
                    };
                  }}
                  to="/about"
                  exact
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7482ff" : "black",
                      fontWeight: isActive ? "500" : "400",
                    };
                  }}
                  to="/servicesPage"
                  exact
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7482ff" : "black",
                      fontWeight: isActive ? "500" : "400",
                    };
                  }}
                  to="/courses"
                >
                  Our Courses
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7482ff" : "black",
                      fontWeight: isActive ? "500" : "400",
                    };
                  }}
                  to="/testimonialPage"
                >
                  Testimonial
                </NavLink>
              </li>
              <li>
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: isActive ? "#7482ff" : "black",
                      fontWeight: isActive ? "500" : "400",
                    };
                  }}
                  to="/gallery"
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" className="navContact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="navBar">
            <div className="navBarBox" id="resNav">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>

      <div className="sidemenu" id="resSidemenu">
        <div className="sidemenuClose" id="navClose">
          <GrClose />
        </div>
        <ul className="burgerMenu">
          <li>
            <NavLink
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7482ff" : "black",
                  fontWeight: isActive ? "500" : "400",
                };
              }}
              to="/"
              className="isActive home"
            >
              Home
            </NavLink>
          </li>

          <li>
            <NavLink
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7482ff" : "black",
                  fontWeight: isActive ? "500" : "400",
                };
              }}
              to="/about"
              className="about"
              exact
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7482ff" : "black",
                  fontWeight: isActive ? "500" : "400",
                };
              }}
              to="/servicesPage"
              className="services"
              exact
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7482ff" : "black",
                  fontWeight: isActive ? "500" : "400",
                };
              }}
              to="/courses"
              className="course"
            >
              Our Courses
            </NavLink>
          </li>
          <li>
            <NavLink
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7482ff" : "black",
                  fontWeight: isActive ? "500" : "400",
                };
              }}
              to="/testimonialPage"
              className="testi"
            >
              Testimonial
            </NavLink>
          </li>
          <li>
            <NavLink
              style={({ isActive }) => {
                return {
                  color: isActive ? "#7482ff" : "black",
                  fontWeight: isActive ? "500" : "400",
                };
              }}
              to="/gallery"
              className="gallry"
            >
              Gallery
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="navContact navEl">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
