import React from "react";
// icons
import { BsSearch } from "react-icons/bs";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineSafetyCertificate } from "react-icons/ai";

function workingProcess() {
  return (
    <div>
      <section id="workingProcess">
        <div className="container">
          <h1>Working Process and Benefits</h1>
          <div className="workingProcessMain">
            <div className="workingProcessGraph">
              <div className="workingProcessGraphBox">
                <span></span>
              </div>
              <div className="workingProcessGraphBox">
                <span></span>
              </div>
              <div className="workingProcessGraphBox">
                <span></span>
              </div>
            </div>
            <div className="workingProcessBoxMain">
              <div className="workingProcessBox">
                <div className="workingProcessBoxThumbnail">
                  <BsSearch />
                </div>
                <h3>Find Course</h3>
              </div>
              <div className="workingProcessBox">
                <div className="workingProcessBoxThumbnail">
                  <BsPlusCircle />
                </div>
                <h3>Book Seat</h3>
              </div>
              <div className="workingProcessBox">
                <div className="workingProcessBoxThumbnail">
                  <AiOutlineSafetyCertificate />
                </div>
                <h3>Certificates</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default workingProcess;
