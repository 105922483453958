import React from "react";
import Footer from "../footer/footer.js";
// icons
import { BsFilterLeft } from "react-icons/bs";
// jquery
import $ from "jquery";

function UgCourses() {
  $(".innerCourseListFilterBox").click(function () {
    $(".innerCourseTab").addClass("innerCourseTabActive");
    $(".shimmer").fadeIn();
  });

  return (
    <div>
      <section id="innerCourseBanner">
        <div className="container">
          <h1>UG Courses</h1>
        </div>
      </section>

      <section id="innerCourseList">
        <div className="container">
          <div className="innerCourseListFilter">
            <div className="innerCourseListFilterBox">
              <i>
                <BsFilterLeft />
              </i>
              <p>Filter</p>
            </div>
          </div>
          <div className="innerCourseTab">
            <div className="innerCourseTabBox">
              <input type="radio" id="course1" name="course-radioBtn" />
              <label for="course1">B.A. Tamil</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course2" name="course-radioBtn" />
              <label for="course2">B.Litt Tamil</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course3" name="course-radioBtn" />
              <label for="course3">B.A. English</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course4" name="course-radioBtn" />
              <label for="course4">B.Sc. Computer Science</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course5" name="course-radioBtn" />
              <label for="course5">B.Sc. Information Technology</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course6" name="course-radioBtn" />
              <label for="course6">Bachelor of Computer Application</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course7" name="course-radioBtn" />
              <label for="course7">B.Sc Costume Design and Fashion</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course8" name="course-radioBtn" />
              <label for="course8">B.Sc Visual Communication</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course9" name="course-radioBtn" />
              <label for="course9">Catering Science and Hotel Management</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course10" name="course-radioBtn" />
              <label for="course10">B.Com</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course11" name="course-radioBtn" />
              <label for="course11">B.Com Computer Application</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course12" name="course-radioBtn" />
              <label for="course12">B.B.A</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course13" name="course-radioBtn" />
              <label for="course13">B.S.W (Social Work)</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course14" name="course-radioBtn" />
              <label for="course14">B.Sc. Mathematics</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course15" name="course-radioBtn" />
              <label for="course15">B.Sc. Physics</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course16" name="course-radioBtn" />
              <label for="course16">B.Sc. Chemistry</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course17" name="course-radioBtn" />
              <label for="course17">B.Sc. Botany</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course18" name="course-radioBtn" />
              <label for="course18">B.Sc. Zoology</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course19" name="course-radioBtn" />
              <label for="course19">BSC Optometry</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course20" name="course-radioBtn" />
              <label for="course20">
                Bachelor of Library & Information Science (B.Lib.I.Sc)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course21" name="course-radioBtn" />
              <label for="course21">BBA - Airline & Airport Management</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course22" name="course-radioBtn" />
              <label for="course22">BBA (Shipping and Port Management)</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course23" name="course-radioBtn" />
              <label for="course23">
                BBA (Logistics and Supply Chain Management)
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course24" name="course-radioBtn" />
              <label for="course24">
                BBA International Shipping and Air Carage Logistics Management I
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course25" name="course-radioBtn" />
              <label for="course25">
                BBA In Airport and Custome Care Management
              </label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course26" name="course-radioBtn" />
              <label for="course26">B.Sc Animation & VFX</label>
            </div>
            <div className="innerCourseTabBox">
              <input type="radio" id="course27" name="course-radioBtn" />
              <label for="course27">
                B.Sc Animation, Game Design & Development
              </label>
            </div>
          </div>
          <div className="innerCourseListMain">
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.A. Tamil Literature</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : தமிழ்</li>
                      <li>2. Part-II : ஆங்கிலம்</li>
                      <li>3. இலக்கியம்-1 : தற்காலம்</li>
                      <li>4. இலக்கணம்-1 : நன்னூல் எழுத்ததிகாரம்.</li>
                      <li>5. தமிழக வரலாறும் பண்பாடும் - 1</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. part-I : தமிழ்</li>
                      <li>7. Part-II : ஆங்கிலம்</li>
                      <li>8. இலக்கியம்-2 : தற்காலம்</li>
                      <li>9. இலக்கணம்-2 : சொல்லதிகாரம்</li>
                      <li>10. தமிழக வரலாறும் பண்பாடும்- 2</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. காப்பியங்கள்</li>
                      <li>
                        12. இலக்கணம்-2 யாப்பருங்கலக்காரிகை (ஒலிபியல் நீங்கலாக)
                      </li>
                      <li>13. இலக்கியம்-3 சங்க காலம்</li>
                      <li>14. இலக்கியத் திறவாய்வு</li>
                      <li>15. தமிழ்மொழி வரலாறு</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 9,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.A. English Literature</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Language Paper I</li>
                      <li>2. English Paper 1</li>
                      <li>3. Core Paper 1 - Prose</li>
                      <li>4. Core Paper II - Fiction</li>
                      <li>5. Allied Paper 1 - Social History of England</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Language Paper II</li>
                      <li>7. English Paper II</li>
                      <li>8. Core Paper III - Poetry</li>
                      <li>9. Core Paper IV - Drama</li>
                      <li>
                        10. Allied Paper - II - History of English Literature
                        and Literary Form
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Core Paper V - Shakespeare</li>
                      <li>12. Core Paper VI - American Literature</li>
                      <li>13. Core Paper VII - Indian Writing in English</li>
                      <li>14. AOS Paper -1 English for Mass Communication</li>
                      <li>15. AOS Paper - II Translation Studies</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 9,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc. Computer Science</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Core -1 : Digital Fundamentals & Architecture</li>
                      <li>4. Core -2 : Data Structures and C Programming</li>
                      <li>
                        5. Allied 1 : Mathematical Foundation for Computer
                        Science
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Core - 3 : C++ Programming</li>
                      <li>
                        7. Core - 4 : System Software and Operating System
                      </li>
                      <li>8. Core - 5 : Software Engineering</li>
                      <li>9. Core - 6 : Java Programming</li>
                      <li>10. I : Programming Lab : C++ and Java</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Core - 7 : Computer Networks</li>
                      <li>12. Core - 8 : RDBMS and Oracle</li>
                      <li>13. Core - 9 : Visual Programming - Visual Basic</li>
                      <li>14. Core - 10 : Software Testing</li>
                      <li>15. Core Lab 2 : Programmable lab: VB and Oracle</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc. Information Technology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Core -1 : Digital Fundamentals & Architecture</li>
                      <li>4. Core -2 : Data Structures and C Programming</li>
                      <li>
                        5. Allied 1 : Mathematical Foundation for Computer
                        Science
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Core - 3 : OOP with C</li>
                      <li>
                        7. Core - 4 : System Software and Operating System
                      </li>
                      <li>8. Core - 5 : Software Engineering</li>
                      <li>9. Core - 6 : Internet and Java Programming</li>
                      <li>Practical – 1 : C++ and Java</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>
                        10. Core - 7 : Principles Of Data Communication &
                        Networks
                      </li>
                      <li>
                        11. Core - 8 : Relational Database Management System &
                        Oracle
                      </li>
                      <li>12. Core - 9 : Visual Programming - Visual Basic</li>
                      <li>13. Core - 10 : Web Technology</li>
                      <li>Practical - II : Visual Basic and Oracle</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Bachelor of Computer Application</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Core -1 : COBOL Programming</li>
                      <li>
                        4. Core -2 : Digital Fundamentals and Architecture
                      </li>
                      <li>
                        5. Allied 1 : Computer Oriented Numerical and
                        Statistical Methods
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Core - 3 : Programming and C and C++</li>
                      <li>7. Core - 4 : Data Structures and Algorithms</li>
                      <li>8. Core - 5 : Software Engineering</li>
                      <li>9. Core - 6 : Operating Systems</li>
                      <li>Practical – I : Programming in C and C++</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>10. Core - 7 : Computer Networks</li>
                      <li>11. Core - 8 : Java Programming</li>
                      <li>
                        12. Core - 9 : : Database Concepts and Visual
                        Programming
                      </li>
                      <li>13. Core - 10 : E-Commerce</li>
                      <li>
                        Practical - II : Programming Lab in Java and Visual
                        Basic
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc Costume Design and Fashion Technology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>
                        3. Core -1 : Fashion Designing & Sewing Technology
                      </li>
                      <li>
                        4. Core -2 : Principles of Pattern Making and Grading
                      </li>
                      <li>
                        Practical – 1 : Fundamentals and Children’s Apparel
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Core - 3 : Fiber to Fabric</li>
                      <li>6. Core - 4 : Fashion and clothing Psychology</li>
                      <li>7. Core - 5 : Organization of Garment Unit</li>
                      <li>Practical - 2 : Women’sApparel</li>
                      <li>Practical - 3 : Fiber To Fabric</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>8. Core - 6 : Textile Wet Processing</li>
                      <li>9. Core - 7 : Fashion and Apparel Marketing</li>
                      <li>10. Core - 8 : Apparel Quality and Management</li>
                      <li>Practical - 4 : Textile Wet Processing</li>
                      <li>Practical - 5 : Computer Aided Design</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc. Visual Communication (Electronic Media)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Core -1 : Introduction to Communication</li>
                      <li>4. Core -2 : Writing for Media</li>
                      <li>5. Core -3 : Advertising</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Part-I : Language - II</li>
                      <li>7. Part-II : Language - II (English)</li>
                      <li>8. Core -4 : Communication Media</li>
                      <li>9. Core -5 : Graphic Production</li>
                      <li>10. Core -6 : Photography</li>
                      <li>Practical - I : Print Production</li>
                      <li>Practical - II : Photography</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Core - 7 : Audiography</li>
                      <li>12. Core - 8 : Commercial Broadcasting</li>
                      <li>
                        13. Core - 9 : Elements of Film and Video Production
                      </li>
                      <li>Practical - III : Commercial Production</li>
                      <li>Practical – IV : Video Production</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.COM</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Principles of Accountancy</li>
                      <li>4. Business Organization & Office Management</li>
                      <li>5. Business Economics</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Financial Accounting</li>
                      <li>7. Principles of Marketing</li>
                      <li>8. Business Statistics,</li>
                      <li>9. Commercial Law & Company Law</li>
                      <li>10. Cost & Management Accounting</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Corporate Accounting</li>
                      <li>12. Principles of Management</li>
                      <li>13. Business Communication</li>
                      <li>14. Auditing</li>
                      <li>15. Banking Law and Practice</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 9,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Com Computer Application</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Principles of Accountancy</li>
                      <li>4. Introduction to Information Technology</li>
                      <li>Practical - I : Computer Application</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Financial Accounting</li>
                      <li>6. Marketing Management</li>
                      <li>7. Data Base Management System</li>
                      <li>8. C++</li>
                      <li>Practical - II :Computer Application</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>9. Cost & Management Accounting</li>
                      <li>10. Business Management</li>
                      <li>11. Business Statistics</li>
                      <li>12. Software Development and Visual Basic</li>
                      <li>Practical - III : Computer Application</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 9,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.B.A</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language – I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Core -1 : Management Process</li>
                      <li>4. Core -2 : Accounting for Managers</li>
                      <li>5. Core -3 : Mathematics for Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Core -4 : Office Management</li>
                      <li>7. Core -5 : Business Communication</li>
                      <li>8. Core -6 : Business Environment</li>
                      <li>9. Core -7 : Financial Accounting</li>
                      <li>10. Core – 8 : Taxation</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Core -9 : Entrepreneurship</li>
                      <li>12. Core -10 : Organizational Behaviour</li>
                      <li>13. Core -11 : Cost & Management Accounting</li>
                      <li>14. Core -12 : Business Law</li>
                      <li>15. Core -13 : Human Resource Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 11,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.S.W.(Social Work)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language Paper- I</li>
                      <li>2. Part-II : English Paper I</li>
                      <li>3. Introduction to Social Work Profession</li>
                      <li>4. Methods of Social Work</li>
                      <li>5. Psychology for Social Work Practice</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Part-I : Language Paper- II</li>
                      <li>7. Part-II : English Paper II</li>
                      <li>8. Sociology for Social Work Practice</li>
                      <li>9. Social Welfare Administration</li>
                      <li>10. Disaster Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Community Development</li>
                      <li>12. Welfare of the Weaker Sections</li>
                      <li>13. NGO Management</li>
                      <li>14. Social Legislation</li>
                      <li>15. Field Work practicum</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 11,000.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc.Mathematics</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language Paper- I</li>
                      <li>2. Part-II : Language Paper- II (English)</li>
                      <li>3. Classical Algebra and Calculus</li>
                      <li>
                        4. Core - II : Trigonometry Vector Calculusand
                        Analytical Geometry
                      </li>
                      <li>5. Allied - I : Statistics for Mathematics</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Part-I : Language Paper- II</li>
                      <li>7. Part-II : Language Paper II (English)</li>
                      <li>
                        8. Core - III : DifferentEquationandLaplace Transforms
                      </li>
                      <li>9. Core - IV : Mechanics</li>
                      <li>10. Allied - II : Accountancy</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Core -V : Real Analysis</li>
                      <li>12. Core -VI : Complex Analysis</li>
                      <li>13. Core -VII : Modern Algebra</li>
                      <li>* Gr.C. Application Oriented Subject -I</li>
                      <li>* Gr.C. Application Oriented Subject -II</li>
                      <li>(1) Numerical Methods.</li>
                      <li>(2) Discrete Mathematics.</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  HSC passed with (General) Mathematics as one of the subjects
                  or 3 year Polytechnic Diploma
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc.Physics</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language Paper- I</li>
                      <li>2. Part-II : Language Paper- II (English)</li>
                      <li>
                        3. General Physics -1 : Heat Thermodynamics Mechanics
                        Properties of Matter and Sound
                      </li>
                      <li>
                        4. General Magnetism : Optics,ElectricityandPhysics
                      </li>
                      <li>5. Allied - A : Mathematics</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Part-I : Language Paper- II</li>
                      <li>7. Part-II : Language Paper II (English)</li>
                      <li>8. Core - III : Mathematical Physics</li>
                      <li>9. Allied - A : Chemistry</li>
                      <li>Core practical - I </li>
                      <li>Core practical - II </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>10. Core - IV : Atomic Physics and NuclearPhysics</li>
                      <li>11. Core - V : Quantum Mechanics and Relativity</li>
                      <li>12. Core - VI : Solid State Physics</li>
                      <li>13. Core - VII : AppliedElectronics</li>
                      <li>Core Practical – III</li>
                      <li>Core Practical - IV</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  HSC passed with Physics, Mathematics, and Chemistry as
                  subjects of study or 3 year Polytechnic Diploma
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc.Chemistry</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Tamil Paper I</li>
                      <li>2. English Paper I</li>
                      <li>3. Chemistry Paper I</li>
                      <li>4. Chemistry Paper II</li>
                      <li>5. Chemistry Practicals I</li>
                      <li>Inorganic Qualitative Analysis</li>
                      <li>Allied : Mathematical Paper</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Tamil Paper II</li>
                      <li>7. English Paper II</li>
                      <li>8. Chemistry Paper III</li>
                      <li>9. Chemistry Paper IV</li>
                      <li>10. Chemistry Practicals II</li>
                      <li>Volumetric and Organic Analysis</li>
                      <li>Allied : General Physics</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>1. Chemistry Paper V</li>
                      <li>2. Chemistry Paper VI</li>
                      <li>3. Chemistry Paper VII</li>
                      <li>4. Chemistry Paper VIII</li>
                      <li>5. Chemistry Practical III</li>
                      <li>Gravimetric and Physical Chemistry</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  HSC passed with (General) Chemistry, as one of the subject or
                  3 years Polytechnic Diploma
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc.Botany</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part - I : LanguageI</li>
                      <li>2. Part - II : English-I</li>
                      <li>
                        3. . Core - 1 : Plant Diversity - I (Algae, Fungi,
                        Lichen and Plant Pathology)
                      </li>
                      <li>
                        4. Core - 2 : Plant Diversity - 2 (Bryophytes,
                        Pteridophytes, Gymnosperms and Paleobotany)
                      </li>
                      <li>5. Practical 1 - Plant Diversity I & II</li>
                      <li>Allied I : Zoology</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Part - I : Language II</li>
                      <li>7. Part - II : English II</li>
                      <li>8. Core - 3 : Anatomy and Embryology</li>
                      <li>9. Core - 4 : Cell Biology and Instrumentation</li>
                      <li>
                        10. Practical 2 - Anatomy and Embryology, Cell Biology
                        and Instrumentation{" "}
                      </li>
                      <li>Allied 2 : Chemistry</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>
                        11. Core - 5 : Genetics, Plant Breeding and
                        Biostatistics
                      </li>
                      <li>
                        12. Core - 6 : Plant Systematics and EconomicBotany
                      </li>
                      <li>13. Core - 7 : Ecology andPhytogeography</li>
                      <li>14. Core - 8 : Plant Physiology andBiochemistry</li>
                      <li>
                        15. Core - 9 : Fundamentals of Microbiology &
                        Biotechnology
                      </li>
                      <li>
                        16. Practical 3 - Genetics,PlantBreeding&Biostatistics,
                        Plant Systematics & Economic Botany, Ecology &
                        Phytogeography
                      </li>
                      <li>
                        17. Practical 4 - Plant physiology & Biochemistry,
                        Fundamentals of Microbiology & Biotechnology
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  HSC passed with Botany / Biology / Vocational Course in
                  Agriculture as a subject of study or 3 years Polytechnic
                  Diploma
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc.Zoology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part - I : LanguageI</li>
                      <li>2. Part - II : English-I</li>
                      <li>3. Core - 1 : Invertebrate Zoology</li>
                      <li>
                        4. Core: Practical 1: Invertebrate Zoology Allied 1 :
                        Botany
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>5. Part - I : Language - II</li>
                      <li>6. Part – II : English-II</li>
                      <li>7. Core – II : Vertebrate Zoology</li>
                      <li>8. Core - 3 : Anatomy and Embryology</li>
                      <li>
                        9. Core : Practical II : Vertebrate Zoology Allied II :
                        Chemistry
                      </li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>9. Core - III : Cell Biology</li>
                      <li>10. Core - IV : Genetics and Evolution</li>
                      <li>
                        11. Core – V : Developmental Biology and Immunology
                      </li>
                      <li>12. Core – VI : Animal Physiology</li>
                      <li>13. Core - VII : Environmental Biology</li>
                      <li>
                        14. Core : Practical III: Cell Biology, Genetics
                        Evolution & Developmental Biology
                      </li>
                      <li>
                        15. Core : Practical IV: Immunology, Animal Physiology &
                        Environmental Biology
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>
                  HSC passed with (General) Zoology / Biology as a subject of
                  study or 3 years Polytechnic Diploma
                </p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>Bachelor of Library & Information Science (B.Lib.I.Sc)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 1 Year</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Library and Society</li>
                      <li>2. Library Management</li>
                      <li>
                        3. Knowledge Organisation -1: Classification Theory
                      </li>
                      <li>
                        4. Knowledge Organisation -II: Classification
                        (Practicals)
                      </li>
                      <li>5.Information Processing -I: Cataloging Theory</li>
                      <li>
                        6. Information Processing-I: Cataloging (Practicals)
                      </li>
                      <li>7. Information Sources</li>
                      <li>8.Information Services</li>
                      <li>9. Fundamentals of Information Technology</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>Any Degree</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>BBA Airline & Airport Management</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Language - I : Tamil</li>
                      <li>2. Language - II : English</li>
                      <li>3. Management Process</li>
                      <li>4. Accounting for Managers</li>
                      <li>5. Mathematics for Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Aviation</li>
                      <li>7. Ancillary Services</li>
                      <li>8. Airline & Airport Organization</li>
                      <li>9. Airline and Travel Management</li>
                      <li>10. Resource and Logistics Management at Airports</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Aircraft Maintenance & Crew Management</li>
                      <li>
                        12. Airline Marketing & Aviation Safety Management
                      </li>
                      <li>
                        13. Principles of Travel, Tourism & Aviation Safety
                      </li>
                      <li>14. Travel Agency Operations</li>
                      <li>
                        15. Service Marketing and Public Relations in
                        theAviation Industry
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 11,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>BBA (Logistics and Supply Chain Management)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Language-I</li>
                      <li>2. Language- II English</li>
                      <li>3. Management Process</li>
                      <li>4. Accounting for Managers</li>
                      <li>5. Mathematics for Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Introduction to Logistics Management</li>
                      <li>7. International Strategic Management</li>
                      <li>8. Principles of Logistics Information</li>
                      <li>9. E-Logistics</li>
                      <li>10. Logistical Operations Integration</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Dry Cargo Chartering</li>
                      <li>12. International Marketing for Logistics and SCM</li>
                      <li>
                        13. Global Supply chain Integration and International
                        Sourcing
                      </li>
                      <li>14. Supply Chain Management</li>
                      <li>15. Retail Supply Chain Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 11,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>
                  BBA International Shipping and Air Cargo Logistics Management
                </h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Language-I</li>
                      <li>2. Language- II (English)</li>
                      <li>3. Management Process</li>
                      <li>4. Accounting for Managers</li>
                      <li>5. Mathematics for Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Organizational Behaviour</li>
                      <li>7. International Strategic Management</li>
                      <li>8. Introduction to Logistics Management</li>
                      <li>9. Inventory Management</li>
                      <li>10. Supply Chain Management</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Principles of Logistics Information</li>
                      <li>12. Foreign Trade Procedure and Documentation</li>
                      <li>13. Air Cargo Logistics Management</li>
                      <li>
                        14. Shipping and Ocean Freight Logistics Management
                      </li>
                      <li>15. Domestic Logistics</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 11,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc Animation & VFX (Annual Pattern)</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. English in Animation</li>
                      <li>2. Language II - French</li>
                      <li>3. Animation Art</li>
                      <li>4. History of art and Color Theory</li>
                      <li>5. Digital Image Compositing</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Modeling</li>
                      <li>7. Texturing</li>
                      <li>8. Lighting</li>
                      <li>9. Rigging</li>
                      <li>10. Animation</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Editing</li>
                      <li>12. Compositing</li>
                      <li>13. 3DFx</li>
                      <li>14. 3D Project & Viva voce</li>
                      <li>15. VFX Project</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Com Actuarial Science</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Language - I : Tamil</li>
                      <li>2. Language - II : English</li>
                      <li>3. Principles of Insurance</li>
                      <li>4. Financial Accounting</li>
                      <li>5. Mathematics For Business</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Principles of Life Insurance</li>
                      <li>7. Principles of Marketing</li>
                      <li>8. Corporate Accounting</li>
                      <li>9. Statistics for Business</li>
                      <li>10. Business Economics</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Cost & Management Accounting</li>
                      <li>12. Executive Communication</li>
                      <li>13. Principles of Reinsurance</li>
                      <li>14. Asset Liability Management</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 9,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc Multimedia and Web Technology</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language - I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>
                        3. Allied 1 : Mathematical Structures for Computer
                        Science
                      </li>
                      <li>4. Core 1 : Data Structures and C Programming</li>
                      <li>5. Core 2 : Computer Organization & Architecture</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>
                        6. Core 3 : Introduction To Web Design & Applications
                      </li>
                      <li>Core 4 : Internet & Java Programming</li>
                      <li>7. Core 5 : Computer Graphics</li>
                      <li>8. Core 6 : Multimedia & Its Applications</li>
                      <li>Practical 1: HTML, XML, Java Script Lab</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>9. Core 7 : Animation Techniques</li>
                      <li>10. Core 8 : Client / Server Computing</li>
                      <li>11. Core 9 : 3Ds Max Animation</li>
                      <li>12. Core 10: Web Technology</li>
                      <li>Practical 2 : Web Technology(ASP.Net)</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.Sc Interior Design</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : Language - I</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>3. Core 1 : Materials of Interiors – I</li>
                      <li>Practical 1 : Interior Design – I</li>
                      <li>Practical 2 : Interior Graphics – I</li>
                      <li>Practical 3 : Presentation Technique</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>3. Core 2 : Materials of Interiors – II</li>
                      <li>4. Core 3 : Estimation and Costing in Interiors</li>
                      <li>Practical 4 : Interior Design – II</li>
                      <li>Practical 5 : Interior Graphics – II</li>
                      <li>Practical 6 : Furniture Design</li>
                      <li>Practical 7 : Computer Application in Design - I</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>5. Core 4 : Landscape and Environmental Design</li>
                      <li>6. Core 5 : Professional Practice and Management</li>
                      <li>Practical 8 : Interior Design – III</li>
                      <li>Practical 9 : Scheme Detailing(Working Drawing)</li>
                      <li>
                        Practical 10 : Computer Application in Design – II
                      </li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
            <div className="innerCourseListBox">
              <div className="innerCourseListBoxHead">
                <h1>B.A Yoga for Human Excellence</h1>
              </div>
              <div className="innerCourseListBoxBody">
                <span>Duration</span>
                <p>Total 3 Years</p>
                <span>Syllabus</span>
                <div className="innerCourseListBoxBodyDuration">
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>1 Year</li>
                      <li>1. Part-I : தமிழ்</li>
                      <li>2. Part-II : Language - II (English)</li>
                      <li>
                        3. Core 1 : : Yogic Life (Body, Life - Force, Mind)
                      </li>
                      <li>4. Core 2 : Sublimation(Personality Development)</li>
                      <li>5. Core 3 : Basic Computer Application</li>
                      <li>Practical 1: Yoga Practice - I</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>2 Year</li>
                      <li>6. Core 4 : திருக்குறள் உட்பொருள் விளக்கம்</li>
                      <li>7. Core 5 : Atomic Poison</li>
                      <li>8. Core 6 : Psychology</li>
                      <li>9. Core 7 : Evolution of Universe</li>
                      <li>10. Core 8: Evolution of Living Beings</li>
                      <li>Practical 2: Yoga Practice - II</li>
                    </ul>
                  </div>
                  <div className="innerCourseListBoxBodyDurationBox">
                    <ul>
                      <li>3 Year</li>
                      <li>11. Core 9: Religions and Principles</li>
                      <li>12. Core 10: Consciousness is truth</li>
                      <li>13. Core 11: Cause and Effect System</li>
                      <li>14. Core 12: Economic Prosperity</li>
                      <li>15. Core 13: Social Welfare and World Peace</li>
                      <li>Practical 3: Yoga Practice - III</li>
                    </ul>
                  </div>
                </div>
                <span>Eligibility</span>
                <p>HSC passed / 3 Year Polytechnic Diploma</p>
                <span>Fees</span>
                <p>₹ 13,500.00</p>
                <a href="tel:+9109496834655" className="innerCourseContact">
                  Contact Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default UgCourses;
